<template>
  <div class="wrapper"><div class="news-corner"></div></div>
  <TheContainer>
    <template v-slot:content>
      <div class="title">
        <h1>{{ news.title }}</h1>
      </div>
      <div class="news-img-wrapper" v-if="media.length > 0">
        <img
          class="news-main-img"
          :src="media[0].attributes.url"
          @click="
            $refs.photoModal.showModal(
              sortedItems(media, idx),
              Array(media.length).fill(news.title)
            )
          "
        />
        <div class="news-img-wrapper-sec" v-if="media.length > 1">
          <div v-for="(item, idx) in media.slice(1)" :key="idx">
            <img
              class="news-sec-img"
              :src="item.attributes.url"
              @click="
                $refs.photoModal.showModal(
                  sortedItems(media, idx + 1),
                  Array(news.photos.data.length).fill(news.title)
                )
              "
            />
          </div>
        </div>
      </div>
      <div class="news-text" v-html="news.content"></div>
      <p class="date">{{ news.date }}</p>
    </template>
  </TheContainer>
  <photo-modal ref="photoModal" />
</template>

<script>
import ApiUtil from "@/utils/api";
import TheContainer from "@/components/layout/TheContainer.vue";
import PhotoModal from "@/components/base/PhotoModal.vue";
import { getCurrentLocale } from "@/utils/current-locale";
export default {
  name: "NewsView",
  components: {
    TheContainer,
    PhotoModal,
  },
  created() {
    this.get();
  },
  data() {
    return {
      news: {},
      media: [],
      date: "",
      text: "",
    };
  },

  methods: {
    async get() {
      /** @type {Record<string, string>} */
      const query = {
        populate: ["photos"].join(","),
      };
      const queryStr = new URLSearchParams(query).toString();
      const res = await ApiUtil({
        url: `novostis/${this.$route.params.id}/${getCurrentLocale()}?${queryStr}`,
        method: "get",
      });

      /**
       * Небольшой костыль, новый эндпоинт возвращает другой формат, без attributes
       * Чтобы не сломать модальные окна и тд - просто переформатируем данные в старый формат
       */
      this.news = {
        ...res.data,
        photos: {
          data:
            res.data.photos?.map((photo) => ({
              attributes: {
                url: photo.url,
              },
            })) ?? [],
        },
      };
      this.media = this.news.photos.data || [];
    },
    sortedItems(items, idx) {
      //if (idx == 0) return items;
      return [...[items[idx]], ...items.slice(idx, items.length), ...items.slice(0, idx)];
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/css/mixins.scss";
h1 {
  color: #000;
  font-size: 60px;
  font-weight: 400;
  line-height: 70px; /* 103.704% */
  text-transform: uppercase;
  margin: 0px;
  @include md {
    font-size: 55px;
    line-height: 60px; /* 103.704% */
  }
  @include sm {
    font-size: 35px;
    line-height: 40px; /* 98.148% */
  }
}
.title {
  padding-top: 20px;
  padding-bottom: 30px;
  @include md {
    padding-top: 22px;
    padding-bottom: 22px;
  }
  @include sm {
    padding-top: 40px;
    padding-bottom: 30px;
  }
}

h3 {
  font-weight: 400;
  color: #000;
  font-size: 48px;
  line-height: 64px; /* 133.333% */
  text-transform: uppercase;
  padding: 0px;
  margin: 0px;
  padding-bottom: 16px;
  @include md {
    font-size: 30px;
    line-height: 40px;
  }
  @include sm {
    font-size: 18px;
    line-height: 22px;
  }
}

.wrapper {
  width: 40rem;
  height: 40rem;
  right: 0px;
  top: -250px;
  position: absolute;
  overflow: hidden;
  @include md {
    width: 20rem;
    height: 20rem;
    top: -100px;
  }
}
.news-corner {
  width: 100%;
  height: 100%;
  transform: rotate(7.761deg);
  background-image: url("~@/assets/images/news-corner.png");
  position: absolute;
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 0.8;
  z-index: -1;
}

.news-text {
  color: #000;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
}

.date {
  font-size: 18px;
  font-weight: 400;
  line-height: 26px; /* 144.444% */
  padding-top: 24px;

  @include md {
    font-size: 16px;
    line-height: 24px; /* 144.444% */
    padding-top: 24px;
  }
  @include sm {
    font-size: 16px;
    line-height: 24px; /* 144.444% */
    padding-top: 24px;
  }
}

.news-img-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
}

.news-main-img {
  width: 100%;
  max-width: 1500px;
  height: auto;
}
.news-img-wrapper-sec {
  width: 100%;
  padding-top: 12px;
  display: flex;
  align-items: center;
  column-gap: 10px;
  padding-bottom: 24px;
  justify-content: space-around;
  max-width: 1500px;
  @include md {
    padding-top: 12px;
    column-gap: 12px;
    padding-bottom: 24px;
    max-width: 800px;
  }
  @include sm {
    padding-top: 12px;
    column-gap: 12px;
    padding-bottom: 24px;
    max-width: 800px;
  }
}

.news-sec-img {
  width: 100%;
  height: auto;
  max-height: 200px;
  object-fit: contain;
}
</style>
