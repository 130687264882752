<template>
  <TheContainer>
    <template v-slot:content>
      <div class="title">
        <h1>{{ title }}</h1>
      </div>
      <div>
        <component
          v-for="compName in componentList"
          :key="compName.__component"
          :is="compName.__component.split('.')[1]"
          v-bind="compName"
        ></component>
      </div>
    </template>
  </TheContainer>
</template>

<script>
import "bootstrap/dist/css/bootstrap.css";

import ApiUtil from "@/utils/api";
import TheContainer from "@/components/layout/TheContainer.vue";
import Card from "@/components/components/Card.vue";
import Stepper from "@/components/components/Stepper.vue";
import Timeline from "@/components/components/Timeline.vue";
import Table from "@/components/components/Table.vue";
import Accordion from "@/components/components/Accordion.vue";
import HtmlBlock from "@/components/components/HtmlBlock.vue";
import Partners from "@/components/components/Partners.vue";

export default {
  name: "DefaultPageBlock",
  components: {
    TheContainer,
    Card,
    Stepper,
    Timeline,
    Table,
    Accordion,
    Partners,
    HtmlBlock,
  },
  mounted() {
    this.get();
  },
  data() {
    return {
      componentList: [],
      title: "",
    };
  },
  computed: {},
  methods: {
    async get() {
      let res = await ApiUtil({
        url:
          "tekstovye-straniczies/?pagination[limit]=1&populate=blocks.image,blocks.rows.cells.value,blocks.steps,blocks.items,blocks.header,blocks.cells,blocks.accordion.items,blocks.timeline.items,blocks.links.image&filters[slug]=" +
          this.$route.params.id,
        method: "get",
      });
      if (res.data.data.length) {
        this.componentList = res.data.data[0].attributes.blocks;
        this.title = res.data.data[0].attributes.title;
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/css/mixins.scss";
h1 {
  color: #000;
  font-size: 60px;
  font-weight: 400;
  line-height: 70px; /* 103.704% */
  text-transform: uppercase;
  margin: 0px;
  @include md {
    font-size: 55px;
    line-height: 60px; /* 103.704% */
  }
  @include sm {
    font-size: 35px;
    line-height: 40px; /* 98.148% */
  }
}
.title {
  padding-top: 20px;
  padding-bottom: 30px;
  @include md {
    padding-top: 22px;
    padding-bottom: 22px;
  }
  @include sm {
    padding-top: 40px;
    padding-bottom: 30px;
  }
}
</style>
