<template>
  <base-modal size="1000" :is-open="isOpen" @close="closeModal" is-image>
    <img class="close" src="@/assets/svg/cross_white.svg" @click="closeModal" />
    <OnClickOutside @trigger="closeModal">
      <swiper
        :slidesPerView="1"
        :centeredSlides="true"
        :modules="modules"
        :pagination="{
          dynamicBullets: true,
        }"
        :spaceBetween="10"
        :loop="true"
        class="swiper"
      >
        <template v-for="(item, idx) in items" :key="idx">
          <swiper-slide>
            <img class="big-image" :src="item.attributes.url" />
            <p class="photom-additional">{{ descriptions[idx] }}</p>
          </swiper-slide>
        </template>
      </swiper>
    </OnClickOutside>
  </base-modal>
</template>

<script>
import BaseModal from "./BaseModal";
import { OnClickOutside } from "@vueuse/components";
import { Swiper, SwiperSlide } from "swiper/vue";
import { Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
export default {
  name: "PhotoModal",
  components: { BaseModal, OnClickOutside, Swiper, SwiperSlide },
  setup() {
    return {
      modules: [Pagination],
    };
  },
  data() {
    return {
      crossSvg: require("@/assets/svg/cross.svg"),
      isOpen: false,
      items: [],
      description: "",
    };
  },

  methods: {
    showModal(items, description) {
      const removed = [...items].slice(1, items.length);
      const removed_descr = [...description].slice(1, items.length);
      this.items = removed;
      this.descriptions = removed_descr;
      this.isOpen = true;
    },
    closeModal() {
      this.isOpen = false;
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/css/mixins.scss";

.big-image {
  padding-top: 20px;
  width: 100%;
  max-height: 70vh;
  object-fit: contain;
  align-items: center;
  margin-left: auto;
}
.close {
  position: absolute;
  right: -20px;
  top: -20px;
  color: #fff;
  cursor: pointer;
  @include md {
    height: 20px;
    width: 20px;
    right: 20px;
    top: 0px;
  }
  @include sm {
    height: 20px;
    width: 20px;
    right: 20px;
    top: -2px;
  }
}

.photom-additional {
  color: #fff;
  text-align: center;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px; /* 100% */
  padding-top: 20px;
  padding-bottom: 20px;
  @include md {
    font-size: 16px;
    padding-bottom: 0px;
  }
  @include sm {
    font-size: 14px;
    line-height: 18px; /* 100% */
    padding-bottom: 0px;
  }
}
</style>
