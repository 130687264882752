<template>
  <the-header />
  <router-view />
  <the-footer />
</template>

<script>
import TheHeader from "@/components/layout/TheHeader";
import TheFooter from "@/components/layout/TheFooter";
export default {
  components: { TheHeader, TheFooter },
  data() {
    return {
      loadLang: false,
    };
  },
  created() {
    document.documentElement.style.setProperty(
      "--scrollbar-width",
      window.innerWidth - document.documentElement.clientWidth + "px"
    );
  },
};
</script>

<style>
#app {
  zoom: 0.8;
  color: #000;
  background-color: var(--main-color);
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
