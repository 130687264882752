<template>
  <TheContainer>
    <template v-slot:default>
      <div class="fonts" v-html="start + img_content + '<div>'"></div>
    </template>
  </TheContainer>
</template>

<script>
import TheContainer from "@/components/layout/TheContainer.vue";

export default {
  name: "HtmlBlock",
  props: {
    content: String,
  },
  components: {
    TheContainer,
  },
  created() {},
  data() {
    return {
      data: { title: "", content: "" },
      start: '<div style="max-width:100%">',
    };
  },
  computed: {
    img_content() {
      return this.content.replace("<img", '<img style="max-width:100%"');
    },
  },
  methods: {},
};
</script>

<style scoped lang="scss">
@import "@/assets/css/mixins.scss";
h1 {
  color: #000;
  font-size: 60px;
  font-weight: 400;
  line-height: 70px; /* 103.704% */
  text-transform: uppercase;
  margin: 0px;
  @include md {
    font-size: 55px;
    line-height: 60px; /* 103.704% */
  }
  @include sm {
    font-size: 35px;
    line-height: 40px; /* 98.148% */
  }
}
.title {
  padding-top: 20px;
  padding-bottom: 30px;
  @include md {
    padding-top: 22px;
    padding-bottom: 22px;
  }
  @include sm {
    padding-top: 40px;
    padding-bottom: 30px;
  }
}
.fonts {
  font-family: Roboto;
}
</style>
