// LocalStorage key for lng
const LOCAL_STORAGE_LANG_KEY = "pangeolng";
// Default lng
const DEFAULT_LANG = "ru";

/**
 * Get current locale
 * @returns {'ru' | 'en'}
 */
export const getCurrentLocale = () => {
  return localStorage.getItem(LOCAL_STORAGE_LANG_KEY) || DEFAULT_LANG;
};

/**
 * Set current locale
 * @param locale {'ru' | 'en'}
 */
export const setCurrentLocale = (locale) => {
  localStorage.setItem(LOCAL_STORAGE_LANG_KEY, locale);
};
