<template>
  <div class="accordion" role="tablist">
    <template v-for="(item, idx) in items" :key="idx">
      <b-card no-body class="mb-1">
        <b-card-header header-tag="header" class="p-1" role="tab">
          <b-button
            block
            :v-b-toggle="'accordion-' + idx"
            style="background: #21a56e"
            @click="n = idx"
            >{{ item.title }}</b-button
          >
        </b-card-header>
        <b-collapse
          :id="'accordion-' + idx"
          :visible="n === idx"
          accordion="my-accordion"
          role="tabpanel"
        >
          <div style="padding: 1rem">
            <b-card-body>
              <b-card-text>{{ item.description }}</b-card-text>
            </b-card-body>
          </div>
        </b-collapse>
      </b-card>
    </template>
  </div>
</template>

<script>
import { BCard, BCollapse, BButton } from "bootstrap-vue-3";

export default {
  name: "AccordionCmp",
  components: {
    BCard,
    BCollapse,
    BButton,
  },
  props: { items: Array },
  data() {
    return {
      n: null, // This controls which accordion item is open
    };
  },
};
</script>
