<template>
  <div class="footer">
    <div class="wrapper-footer">
      <router-link class="corner" to="/">
        <img :src="footerIcon" />
      </router-link>
      <div class="menu">
        <div style="display: flex; flex-direction: column" v-if="links.length > 0">
          <template v-for="(link, idx) in links" :key="idx">
            <a class="header__nav" target="_blank" :href="link.link">
              {{ link.name }}
            </a>
          </template>
        </div>
        <div style="display: flex; flex-direction: column">
          <router-link class="header__nav" to="/news">{{
            this.translate["news"][this.lng]
          }}</router-link>
          <router-link class="header__nav" to="/about">{{
            this.translate["about"][this.lng]
          }}</router-link>
          <router-link class="header__nav" to="/presale">{{
            this.translate["info"][this.lng]
          }}</router-link>
          <a class="header__nav" target="_blank" href="https://docs.pangeoradar.ru/">
            {{ this.translate["docs"][this.lng] }}
          </a>
        </div>
        <div style="display: flex; flex-direction: column">
          <a class="header__nav" href="callto:74952520304">+7 (495) 252-03-04</a>
          <a class="header__nav" href="mailto:info@pangeoradar.ru">info@pangeoradar.ru</a>
          <a class="header__nav" target="_blank" href="https://portal.pangeoradar.ru/">{{
            this.translate["tech"][this.lng]
          }}</a>
          <div class="header__nav" @click="openContactModal('price')">
            {{ this.translate["price"][this.lng] }}
          </div>
        </div>
      </div>

      <div class="menu-mobile">
        <router-link class="header__nav" to="/about">{{
          this.translate["about"][this.lng]
        }}</router-link>
        <router-link class="header__nav" to="/news">{{
          this.translate["news"][this.lng]
        }}</router-link>
        <router-link class="header__nav" to="/presale">{{
          this.translate["info"][this.lng]
        }}</router-link>
        <div v-if="links.length > 0">
          <template v-for="(link, idx) in links" :key="idx">
            <a class="header__nav" target="_blank" :href="link.link">
              {{ link.name }}
            </a>
          </template>
        </div>
        <a class="header__nav" target="_blank" href="https://portal.pangeoradar.ru/">{{
          this.translate["tech"][this.lng]
        }}</a>
        <a class="header__nav" href="https://docs.pangeoradar.ru/">
          {{ this.translate["docs"][this.lng] }}
        </a>
        <div class="header__nav" @click="openContactModal('price')">
          {{ this.translate["price"][this.lng] }}
        </div>
        <a class="header__nav" href="callto:74952520304">+7 (495) 252-03-04</a>
        <a class="header__nav" href="mailto:info@pangeoradar.ru">info@pangeoradar.ru</a>
      </div>
    </div>

    <div class="bottom">
      <div
        class="scrolling-text"
        ref="text"
        @click="openContactModal('demo')"
        v-if="this.$route.path !== '/partners'"
      >
        {{ this.translate["request"][this.lng].split(" ").slice(0, 1).join(" ") }}<br />
        {{ this.translate["request"][this.lng].split(" ").slice(1, 100).join(" ") }}
        <img :src="arrowRight" class="corner" />
      </div>
      <div v-else class="scrolling-text" ref="text" @click="openContactModal('partner')">
        {{ this.translate["partner"][this.lng].split(" ").slice(0, 1).join(" ") }}<br />
        {{ this.translate["partner"][this.lng].split(" ").slice(1, 100).join(" ") }}
        <img :src="arrowRight" class="corner" />
      </div>
    </div>
    <div class="bottom">
      <div class="company">
        <p>©2019-{{ new Date().getFullYear() }} OOO “Пангео Радар”</p>
        <p>Деятельность осуществляется при грантовой поддержке Фонда «Сколково»</p>
      </div>
    </div>
  </div>
  <contact-modal ref="contactModal" />
</template>

<script>
import ApiUtil from "@/utils/api";
import ContactModal from "@/components/base/ContactModal.vue";
import translate from "@/utils/translate";
import { getCurrentLocale } from "@/utils/current-locale";
export default {
  name: "TheFooter",
  data() {
    return {
      footerIcon: require("@/assets/svg/footer-icon.svg"),
      arrowRight: require("@/assets/svg/arrow-right-big.svg"),
      skolkovoIcon: require("@/assets/svg/sk-member.svg"),
      lng: "",
      translate,
      contactTitle: "",
      isPrice: false,
      links: [],
    };
  },
  props: {},
  computed: {},
  components: {
    ContactModal,
  },
  created() {
    this.lng = getCurrentLocale();
    this.get();
  },
  methods: {
    openContactModal(type) {
      this.$refs.contactModal.showModal(type);
    },
    async get() {
      let populate = ["additionalLinks.link"];
      let res = await ApiUtil({
        url: "podval?populate=" + populate.join(","),
        method: "get",
      });
      this.links = res.data.data.attributes.additionalLinks;
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/css/mixins.scss";
.footer {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  margin-bottom: 0px;
  padding: 0px;
  padding-top: 88px;
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.7) 0 100%),
    url("~@/assets/images/footer.png");
  background-repeat: no-repeat;
  background-size: 90rem;
  background-position: 40vw 0;
  @include md {
    padding-top: 88px;
    background-size: 60rem;
    background-position: 20vw 0;
  }
  @include sm {
    display: block;
    padding-top: 48px;
    background-size: 40rem;
    background-position: 10vw 150px;
  }
}

.wrapper-footer {
  display: flex;
  justify-content: space-between;
  max-width: 1500px;
  width: 100%;
  padding: 0px 88px;
  align-items: flex-start;
  @include md {
    max-width: 800px;
    padding: 10px 40px;
    padding-top: 20px;
  }
  @include sm {
    display: block;
    max-width: 700px;
    padding: 10px 20px;
  }
}

.bottom {
  display: flex;
  justify-content: space-between;
  max-width: 1500px;
  width: 100%;
  padding: 0px 88px;
  align-items: flex-end;
  text-align: left;
  gap: 32px;
  @include md {
    max-width: 800px;
    padding: 10px 40px;
    padding-top: 20px;
  }
  @include sm {
    align-items: flex-start;
    flex-direction: column;
    max-width: 700px;
    padding: 10px 20px;
  }
}
.corner {
  display: inline-flex;
  height: 100px;
  width: 100px;
  @include md {
    height: 100px;
    width: 100px;
  }
  @include sm {
    height: 100px;
    width: 100px;
  }
}

.menu {
  display: flex;
  flex-direction: row;
  text-align: left;
  column-gap: 130px;
  padding-bottom: 60px;
  @include md {
    column-gap: 40px;
    padding-bottom: 40px;
  }
  @include sm {
    display: none;
  }
}

.menu-mobile {
  display: none;
  @include sm {
    display: grid;
    grid-template-columns: 1fr;
    text-align: left;
    padding-top: 20px;
  }
}

.header__nav {
  color: #4d5759;
  font-size: 16px;
  font-weight: 500;
  line-height: 35px;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    color: #21a56e;
  }
  @include md {
    font-size: 13px;
    line-height: 30px;
  }
}
.scrolling-text {
  cursor: pointer;
  white-space: nowrap;
  font-size: 108px;
  font-style: normal;
  font-weight: 400;
  line-height: 112px; /* 103.704% */
  color: white; /* Set the font color to white */
  text-shadow: -1px -1px 0 black, 1px -1px 0 black, -1px 1px 0 black, 1px 1px 0 black; /* Add a black stroke */
  text-transform: uppercase;
  text-align: left;
  &:hover {
    transition: 0.3s;
    color: #21a56e;
    text-shadow: -1px -1px 0 #21a56e, 1px -1px 0 #21a56e, -1px 1px 0 #21a56e,
      1px 1px 0 #21a56e; /* Add a black stroke */
  }
  @include md {
    font-size: 70px;
    line-height: 74px; /* 103.704% */
  }
  @include sm {
    font-size: 40px;
    line-height: 44px; /* 103.704% */
    padding-bottom: 10px;
  }
}
.company {
  color: #4d5759;
  font-size: 16px;
  font-weight: 500;
  opacity: 0.5;
  text-align: left;
  margin-right: auto;
  padding-top: 29px;
  padding-bottom: 30px;
  @include md {
    font-size: 13px;
    line-height: 30px;
    padding-top: 19px;
    padding-bottom: 20px;
  }
  @include sm {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  & > p {
    margin: 0;
  }
}
</style>
