<template>
  <div class="kit">
    <div class="kit-title">{{ title }}</div>
    <div class="kit-subtitle">
      {{ subtitle }}
      <div>
        <a class="link" :href="linkLink" target="_blank">{{ linkName }}</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BaseNewsTtitle",
  props: {
    title: String,
    subtitle: String,
    linkName: String,
    linkLink: String,
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/css/mixins.scss";
.kit-title {
  color: #000;
  font-size: 32px;
  font-weight: 200;
  line-height: 48px; /* 150% */
  text-transform: uppercase;
  padding-bottom: 24px;
  @include md {
    font-size: 26px;
    line-height: 36px;
    padding-bottom: 12px;
  }
  @include sm {
    font-size: 16px;
    line-height: 26px;
    padding-bottom: 8px;
  }
}

.kit-subtitle {
  color: #000;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px; /* 133.333% */
  @include md {
    font-size: 16px;
    line-height: 25px;
  }
  @include sm {
    font-size: 16px;
    line-height: 25px;
    max-width: 400px;
  }
}

.link {
  cursor: pointer;
  color: #21a56d;
  @include sm {
    word-break: break-all;
  }
}

.kit {
  display: grid;
  grid-template-columns: 1fr;
  padding: 40px 0px;
  column-gap: 60px;
  border-top: 1px solid #00000011;
  border-bottom: 1px solid #00000011;
  @include md {
    padding: 15px 0px;
    column-gap: 30px;
  }
  @include sm {
    padding: 15px 0px;
    display: flex;
    flex-direction: column;
    border-top: 0px solid #00000011;
    width: 100%;
  }
}
</style>
