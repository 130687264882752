<template>
  <TheContainer>
    <template v-slot:content>
      <div class="title">
        <h1>404</h1>
      </div>
      <div>Такой страницы не найдено</div>
    </template>
  </TheContainer>
</template>

<script>
import ApiUtil from "@/utils/api";
import TheContainer from "@/components/layout/TheContainer.vue";
export default {
  name: "NewsView",
  components: {
    TheContainer,
  },
  created() {
    this.get();
  },
  data() {
    return { data: { title: "", content: "" } };
  },

  methods: {
    async get() {
      let res = await ApiUtil({
        url:
          "tekstovye-straniczies/?pagination[limit]=1&filters[slug]=" +
          this.$route.params.id,
        method: "get",
      });
      if (res.data.data.length) {
        this.data = res.data.data[0].attributes;
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/css/mixins.scss";
h1 {
  color: #000;
  font-size: 108px;
  font-weight: 400;
  line-height: 112px; /* 103.704% */
  text-transform: uppercase;
  margin: 0px;
  @include md {
    font-size: 55px;
    line-height: 60px; /* 103.704% */
  }
  @include sm {
    font-size: 35px;
    line-height: 40px; /* 98.148% */
  }
}
.title {
  padding-top: 42px;
  padding-bottom: 88px;
  @include md {
    padding-top: 22px;
    padding-bottom: 22px;
  }
  @include sm {
    padding-top: 40px;
    padding-bottom: 30px;
  }
}
</style>
