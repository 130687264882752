<template>
  <TheContainer>
    <template v-slot:content>
      <!--<div class="image_container_int">
        <div class="image_shield" />
        <div class="image_ice" />
      </div>-->
      <img src="@/assets/images/shield.png" class="image_shield_test" />
      <div class="int-title-mob">
        <h2 class="title-interaction">
          {{ mainAdvantages[2]["title"] }}
        </h2>
        <div class="description-top">
          <div class="point"></div>
          <div class="point subtitle-interaction">
            {{ mainAdvantages[2]["description"] }}
          </div>
        </div>
      </div>
      <div class="description-top">
        <div class="point">
          <hr class="line" />
          <h3>{{ mainAdvantages[0]["title"] }}</h3>
          {{ mainAdvantages[0]["description"] }}
        </div>
        <div class="point">
          <hr class="line" />
          <h3>{{ mainAdvantages[1]["title"] }}</h3>
          {{ mainAdvantages[1]["description"] }}
        </div>
        <div class="image_shield_mob" />
      </div>
      <div class="int-title">
        <div class="description-top-main">
          <p class="title-interaction">
            {{ mainAdvantages[2]["title"].split(" ").slice(0, 1).join(" ") }}<br />
            {{ mainAdvantages[2]["title"].split(" ").slice(1, 100).join(" ") }}
          </p>
          <!--<img src="@/assets/images/shield.png" class="image_shield" />-->
        </div>

        <div class="description-top">
          <!-- <div class="point"><img src="@/assets/images/ice.png" class="image_ice" /></div>-->
          <div class="point subtitle-interaction">
            {{ mainAdvantages[2]["description"] }}
          </div>
        </div>
      </div>
      <div class="description-top">
        <div class="point">
          <hr class="line" />
          <h3>{{ secondaryAdvantages[0]["title"] }}</h3>
          {{ secondaryAdvantages[0]["description"] }}
        </div>
        <div class="point">
          <hr class="line" />
          <h3>{{ secondaryAdvantages[1]["title"] }}</h3>
          {{ secondaryAdvantages[1]["description"] }}
        </div>
        <!--<div class="image_ice_mob" />-->
      </div>
    </template>
  </TheContainer>
</template>

<script>
import TheContainer from "@/components/layout/TheContainer.vue";
export default {
  name: "InteractionPage",
  props: {
    msg: String,
    pageData: Object,
  },
  mounted() {
    this.get();
    //  window.addEventListener("scroll", this.handleScroll);
  },
  data() {
    return {
      mainAdvantages: [
        { title: "", description: "" },
        { title: "", description: "" },
        { title: "", description: "" },
        { title: "", description: "" },
      ],
      secondaryAdvantages: [
        { title: "", description: "" },
        { title: "", description: "" },
        { title: "", description: "" },
        { title: "", description: "" },
      ],
    };
  },
  methods: {
    async get() {
      let pageData = this.pageData;
      this.mainAdvantages = pageData.mainAdvantages;
      this.secondaryAdvantages = pageData.secondaryAdvantages;
    },
  },
  components: {
    TheContainer,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/assets/css/mixins.scss";
h2 {
  color: #000;
  font-size: 80px;
  font-weight: 500;
  line-height: 90px; /* 112.5% */
  text-transform: uppercase;
  text-align: left;
  margin-bottom: 18px;
  padding-top: 152px;
  margin-top: 0px;
  @include md {
    margin-bottom: 0px;
    padding-top: 52px;
    font-size: 40px;
    line-height: 50px; /* 112.5% */
  }
  @include sm {
    font-size: 28px;
    line-height: 30px;
    margin-bottom: 0px;
    padding-top: 0px;
  }
}

h3 {
  font-weight: 400;
  color: #000;
  font-size: 48px;
  line-height: 64px; /* 133.333% */
  text-transform: uppercase;
  padding: 0px;
  margin: 0px;
  padding-bottom: 16px;
  @include md {
    padding-bottom: 8px;
    font-size: 24px;
    line-height: 32px; /* 133.333% */
  }
  @include sm {
    font-size: 18px;
    line-height: 22px;
    padding-bottom: 8px;
  }
}

.description-top-main {
  display: grid;
  grid-template-columns: 2fr 1fr;
  text-align: left;
  color: #000;
  font-size: 80px;
  line-height: 90px; /* 112.5% */
  text-transform: uppercase;
  align-items: end;
  @include md {
    row-gap: 24px;
    column-gap: 26px;
    font-size: 40px;
    line-height: 50px; /* 112.5% */
  }
  @include sm {
    grid-template-columns: 1fr;
    row-gap: 12px;
    column-gap: 13px;
    font-size: 18px;
    line-height: 22px;
  }
}

.description-top {
  display: grid;
  grid-template-columns: 1fr 1fr;
  text-align: left;
  color: #000;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  row-gap: 48px;
  column-gap: 52px;
  @include md {
    row-gap: 24px;
    column-gap: 26px;
  }
  @include sm {
    grid-template-columns: 1fr;
    row-gap: 12px;
    column-gap: 13px;
  }
}
.point {
  color: #000;
  font-size: 24px;
  font-weight: 400;
  line-height: normal;
  @include md {
    font-size: 16px;
  }
  @include sm {
    font-size: 14px;
  }
}

.title-interaction {
  padding: 50px 0px;
  color: #0057ff;
  @include md {
    padding: 60px 0px;
  }
  @include sm {
    padding: 10px 0px;
  }
}
.subtitle-interaction {
  padding-bottom: 50px;
  @include md {
    padding-bottom: 50px;
  }
  @include sm {
    padding-bottom: 30px;
  }
}

.line {
  margin-bottom: 18px;
  width: 100%;
  height: 0px;
  stroke-width: 2px;
  stroke: #000;
  opacity: 0.1;
  @include sm {
    width: 300px;
  }
}

.image_container_int {
  max-width: 1300px;
  width: 100%;
  height: auto;
  position: absolute;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  z-index: -1;
  @include md {
    max-width: 700px;
  }
  @include sm {
    display: none;
  }
}

.image_shield_mob {
  display: none;
  @include sm {
    filter: brightness(1.1);
    display: flex;
    position: relative;
    background-image: url("~@/assets/images/shield.png");
    background-repeat: no-repeat;
    width: 190px;
    height: 190px;
    background-size: fit;
    background-size: 100%;
  }
}

.image_ice_mob {
  display: none;
  @include sm {
    filter: brightness(1.1);
    display: flex;
    position: relative;
    background-image: url("~@/assets/images/ice.png");
    background-repeat: no-repeat;
    width: 190px;
    height: 190px;
    background-size: fit;
    background-size: 100%;
  }
}

.int-title {
  max-width: 100%;
  @include sm {
    display: none;
  }
}

.int-title-mob {
  display: none;
  @include sm {
    display: block;
  }
}

.image_ice {
  width: 80%;
  height: auto;
  filter: brightness(1.1);
  object-fit: cover;
  position: relative;
  z-index: -1;
  opacity: 0.5;
  @include sm {
    display: none;
  }
}
.image_shield_test {
  position: absolute;
  height: auto;
  filter: brightness(1.1);
  object-fit: cover;
  //position: relative;
  z-index: -1;
  opacity: 0.1;
  @include md {
    width: 100%;
    left: 0px;
  }
  @include sm {
    display: none;
  }
}
.image_shield {
  width: 100%;
  height: auto;
  filter: brightness(1.1);
  object-fit: cover;
  position: relative;
  z-index: -1;
  opacity: 0.5;
  @include sm {
    display: none;
  }
}
</style>
