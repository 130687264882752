<template>
  <div v-if="showPage">
    <div class="ellipse-1"></div>
    <div class="ellipse-2"></div>
    <TopPage :pageData="pageData" />
    <div ref="about"><CollectorPage :pageData="pageData" /></div>
    <div ref="architecture"><InteractionPage :pageData="pageData" /></div>
    <SopkaPage :pageData="pageData" />
    <div ref="features"><KeySlidePage :pageData="pageData" /></div>
    <CustomerBlock :pageData="pageData" />
    <ParnersBlock :pageData="pageData" />
  </div>
  <!--<div v-else class="loading">
    <div><img :src="headerIcon" class="header-logo" /></div>
  </div>-->
</template>

<script>
import ApiUtil from "@/utils/api";

import TopPage from "@/components/pages/main/TopPage.vue";
import CollectorPage from "@/components/pages/main/CollectorPage.vue";
import InteractionPage from "@/components/pages/main/InteractionPage.vue";
import SopkaPage from "@/components/pages/main/SopkaPage.vue";
import KeySlidePage from "@/components/pages/main/KeySlidePage.vue";
import CustomerBlock from "@/components/pages/main/CustomerBlock.vue";
import ParnersBlock from "@/components/pages/main/ParnersBlock.vue";
export default {
  name: "HomeView",
  components: {
    TopPage,
    CollectorPage,
    InteractionPage,
    SopkaPage,
    KeySlidePage,
    CustomerBlock,
    ParnersBlock,
  },
  data() {
    return {
      hash: "",
      pageData: {},
      showPage: false,
      headerIcon: require("@/assets/svg/footer-icon.svg"),
    };
  },
  created() {
    this.showPage = false;
    this.hash = this.$route.hash;
  },

  async mounted() {
    await this.get();
    if (this.hash) {
      var element = this.$refs[this.hash.slice(1)];
      window.scrollTo(0, element.offsetTop);
    }
  },
  watch: {
    $route(to, from) {
      console.log(to, from);
      this.hash = this.$route.hash;
    },
    hash(val) {
      if (val && this.showPage) {
        var element = this.$refs[val.slice(1)];
        window.scrollTo(0, element.offsetTop);
      }
    },
  },
  methods: {
    handleScroll() {
      const scrolled = window.scrollY * 5;
      this.$refs.text.style.transform = `translateX(-${scrolled}px)`;
    },

    async get() {
      let populate = [
        "mainBanner",
        "scrollingText",
        "mainBanner.presentation",
        "mainBanner.techPresentation",
        "eventsQueue.balancer",
        "eventsQueue.worker",
        "eventsQueue.data",
        "eventsQueue.eventEmitter",
        "eventsQueue.collector",
        "eventsQueue.master",
        "eventsQueue.correlator",
        "eventsQueue.apm",
        "mainAdvantages",
        "secondaryAdvantages",
        "ellipsesText",
        "keyFeatures.elements.image",
        "partners.items.image",
        "customers.items.image",
      ];
      let res = await ApiUtil({
        url: "glavnaya-stranicza?populate=" + populate.join(","),
        method: "get",
      });
      this.pageData = res.data.data.attributes;
      this.showPage = true;
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/css/mixins.scss";

.ellipse-1 {
  top: -300px;
  left: -700px;
  z-index: -1;
  position: absolute;
  width: 1052.308px;
  height: 537.846px;
  flex-shrink: 0;
  border-radius: 1052.308px;
  opacity: 0.3;
  background: #21a56d;
  filter: blur(122.44999694824219px);
  @include md {
    width: 792.308px;
  }
  @include sm {
    width: 250.308px;
  }
}
.ellipse-2 {
  top: -350px;
  left: 50px;
  z-index: -1;
  position: absolute;
  width: 800.473px;
  height: 381.349px;
  flex-shrink: 0;
  border-radius: 800.473px;
  background: #c1ff84;
  filter: blur(152.89999389648438px);
  @include md {
    width: 700.473px;
  }
  @include sm {
    width: 200.308px;
  }
}

.loading {
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 20;
  max-height: 100vh;
  min-height: 100vh;
  background: white;
  transition: 5s;
}
</style>
