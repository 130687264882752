import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import EventsView from '../views/EventsView.vue'
import NewsView from '../views/NewsView.vue'
import NewsPage from '../components/pages/NewsPage.vue'
import EventsPage from '../components/pages/EventsPage.vue'
import DefaultPageBlock from '../components/pages/DefaultPageBlock.vue'
import NotFoundPage from '../components/pages/NotFoundPage.vue'
import PresaleKitView from '../views/PresaleKitView.vue'
import AboutView from '../views/AboutView.vue'
import ComponentPage from '../components/pages/ComponentPage.vue'
//import PartnersView from '../views/PartnersView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/news',
    name: 'news',
    component: NewsView
  },
  {
    path: '/events',
    name: 'events',
    component: EventsView
  },
 // {
 //   path: '/partners',
 //   name: 'partners',
 //   component: PartnersView
 // },
  {
    path: '/news/:id',
    name: 'news-page',
    component: NewsPage,
    props: true
  },
  {
    path: '/events/:id',
    name: 'events-page',
    component: EventsPage,
    props: true
  },
  {
    path: '/pages/:id',
    name: 'page',
    component: DefaultPageBlock,
    props: true
  },
  {
    path: '/presale',
    name: 'presale',
    component: PresaleKitView
  },
  {
    path: '/about',
    name: 'about',
    component: AboutView
  },
  {
    path: '/:catchAll(.*)*',
    component: NotFoundPage
  },
  {
    path: '/cmp-lib',
    component: ComponentPage
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),

  routes,
  scrollBehavior(to) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth',
      }
    }
    return { top: 0 };
  }
})

export default router
