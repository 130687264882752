<template>
  <div class="wrapper"><div class="news-corner"></div></div>
  <TheContainer>
    <template v-slot:content>
      <div class="title">
        <h1>{{ this.translate["news_title"][this.lng] }}</h1>
      </div>
      <div v-if="news.length > 0">
        <template v-for="(item, idx) in news.slice(0, nnews)" :key="idx">
          <BaseNewsTtitle
            :title="item.attributes.title"
            :date="item.attributes.date"
            :imageSrc="item.attributes.photos?.data?.[0]?.attributes?.url || ''"
            :id="item.id"
          />
        </template>
        <div class="showMore" v-if="nnews <= news.length" @click="nnews += 5">
          Показать больше
        </div>
      </div>
      <div v-else class="empty-news">
        {{ this.translate["news_empty"][this.lng] }}
      </div>
    </template>
  </TheContainer>
</template>

<script>
import ApiUtil from "@/utils/api";
import TheContainer from "@/components/layout/TheContainer.vue";
import BaseNewsTtitle from "@/components/base/BaseNewsTtitle.vue";
import translate from "@/utils/translate";
import { getCurrentLocale } from "@/utils/current-locale";
export default {
  name: "NewsView",
  components: {
    TheContainer,
    BaseNewsTtitle,
  },
  data() {
    return {
      news: [],
      title: [],
      lng: "ru",
      nnews: 5,
      translate,
    };
  },
  created() {
    this.get();
    this.lng = getCurrentLocale();
  },
  methods: {
    async get() {
      /** @type {Record<string, string>} */
      const query = {
        populate: ["photos"].join(","),
        sort: "date:desc",
      };
      const queryStr = new URLSearchParams(query).toString();
      let res = await ApiUtil({
        url: `novostis?${queryStr}`,
        method: "get",
      });
      this.news = res.data.data;
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/css/mixins.scss";
h1 {
  color: #000;
  font-size: 108px;
  font-weight: 400;
  line-height: 112px; /* 103.704% */
  text-transform: uppercase;
  margin: 0px;
  @include md {
    font-size: 55px;
    line-height: 60px; /* 103.704% */
  }
  @include sm {
    font-size: 35px;
    line-height: 40px; /* 98.148% */
  }
}
.title {
  padding-top: 42px;
  padding-bottom: 88px;
  @include md {
    padding-top: 22px;
    padding-bottom: 48px;
  }
  @include sm {
    padding-top: 40px;
    padding-bottom: 30px;
  }
}

.wrapper {
  width: 40rem;
  height: 40rem;
  right: 0px;
  top: -250px;
  position: absolute;
  overflow: hidden;
  @include md {
    width: 20rem;
    height: 20rem;
    top: -100px;
  }
  @include sm {
    width: 10rem;
    height: 10rem;
    top: -10px;
    right: 0px;
  }
}
.news-corner {
  width: 100%;
  height: 100%;
  transform: rotate(7.761deg);
  background-image: url("~@/assets/images/news-corner.png");
  position: absolute;
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 0.8;
  z-index: -1;
}

.showMore {
  color: #4d5759;
  padding: 10px;
  text-align: center;
  background: #4d575922;
  border-radius: 12px;
  cursor: pointer;
  font-size: 16px;
  @include md {
    font-size: 13px;
  }
  @include sm {
    font-size: 13px;
  }
}
.empty-news {
  color: #4d5759;
  font-size: 16px;
  font-weight: 500;
  opacity: 0.5;
  text-align: left;
  margin-right: auto;
  padding-top: 29px;
  padding-bottom: 30px;
  @include md {
    font-size: 13px;
    line-height: 30px;
    padding-top: 19px;
    padding-bottom: 20px;
  }
  @include sm {
    padding-top: 5px;
    padding-bottom: 5px;
  }
}
</style>
