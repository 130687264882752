<template>
  <TheContainer>
    <template v-slot:content>
      <h1>Example</h1>
      <div>
        <component
          v-for="compName in componentList"
          :key="compName.__component"
          :is="compName.__component.split('.')[1]"
          v-bind="compName"
        ></component>
      </div>
    </template>
  </TheContainer>
  <TheContainer>
    <template v-slot:content>
      <h1>Cards</h1>
      <ul>
        <li>Image URL</li>
        <li>Title</li>
        <li>Text</li>
        <li>Type:[img-top,img-left]</li>
        <li>Background color</li>
        <li>Link for button</li>
      </ul>
      <div class="cards-wrapper">
        <card
          :title="cardData.title"
          :imgsrc="cardData.imgsrc"
          :text="cardData.text"
          :type="cardData.type"
          :btnText="cardData.btnText"
          :btnHref="cardData.btnHref"
        ></card>
      </div>

      <h1>Stepper</h1>
      <ul>
        <li>Top: Color in bg-nottation</li>
        <li>Top: text</li>
        <li>Bottom: Text title</li>
        <li>Bottom: Text description</li>
        <li>Link for button</li>
      </ul>
      <stepper :items="timelineItems" />
      <h1>Timeline</h1>
      <ul>
        <li>Titile</li>
        <li>Subtitle (if exists)</li>
        <li>Description</li>
      </ul>
      <timeline :items="timelineItems" />
      <h1>Table</h1>
      <ul>
        <li>items: [</li>
        <li>{ age: 40, first_name: "Dickerson", last_name: "Macdonald" },</li>
        <li>{ age: 21, first_name: "Larsen", last_name: "Shaw" },</li>
        <li>{ age: 89, first_name: "Geneva", last_name: "Wilson" },</li>
        <li>{ age: 38, first_name: "Jami", last_name: "Carney" },</li>
        <li>],</li>
      </ul>

      <Table :items="tableItems" />
      <h1>Accordion</h1>
      <ul>
        <li>Menu: Color in bg-nottation and text</li>
        <li>Bottom: Text description</li>
        <li>Logic flag: Only one open or all can ve open</li>
      </ul>
      <Accordion :items="timelineItems" />

      <h1>Partners</h1>
      <partners :title="partners.title" :items="partners.items" />
    </template>
  </TheContainer>
</template>
<script>
import "bootstrap/dist/css/bootstrap.css";

import ApiUtil from "@/utils/api";
import TheContainer from "@/components/layout/TheContainer.vue";
import Card from "@/components/components/Card.vue";
import Stepper from "@/components/components/Stepper.vue";
import Timeline from "@/components/components/Timeline.vue";
import Table from "@/components/components/Table.vue";
import Accordion from "@/components/components/Accordion.vue";
import HtmlBlock from "@/components/components/HtmlBlock.vue";
import Partners from "@/components/components/Partners.vue";
export default {
  name: "NewsView",
  components: {
    TheContainer,
    Card,
    Stepper,
    Timeline,
    Table,
    Accordion,
    Partners,
    HtmlBlock,
  },
  // Note that Vue automatically prefixes directive names with `v-`

  mounted() {
    this.get();
  },

  data() {
    return {
      data: { title: "", content: "" },
      componentList: [],
      cardData: {
        title: "Contact 1",
        imgsrc: "https://i.stack.imgur.com/Ryvwp.jpg?s=64&g=1",
        text: "Director of evrything",
        type: "img-left",
        btnText: "Call",
        btnHref: "/",
      },
      tableItems: [
        { age: 40, first_name: "Dickerson", last_name: "Macdonald" },
        { age: 21, first_name: "Larsen", last_name: "Shaw" },
        { age: 89, first_name: "Geneva", last_name: "Wilson" },
        { age: 38, first_name: "Jami", last_name: "Carney" },
      ],
      timelineItems: [
        { title: "Step 1", date: "10 March 2024", description: "Macdonald" },
        { title: "Step 3", date: "10 April 2024", description: "Macdonald" },
        { title: "Step 4", date: "10 May 2024", description: "Macdonald" },
        { title: "Step 5", date: "10 June 2024", description: "Macdonald" },
      ],
      partners: {
        title: "Title1",
        items: [
          { title: "Step 1", date: "10 March 2024", description: "Macdonald" },
          { title: "Step 3", date: "10 April 2024", description: "Macdonald" },
          { title: "Step 4", date: "10 May 2024", description: "Macdonald" },
          { title: "Step 5", date: "10 June 2024", description: "Macdonald" },
        ],
      },
    };
  },

  methods: {
    async get() {
      let res = await ApiUtil({
        url:
          "https://cms.dev.pangeo.k8s.geexup.cloud/api/tekstovye-straniczies/?pagination[limit]=1&filters[slug]=test&locale=ru&populate=blocks.image,blocks.rows.cells.value,blocks.steps,blocks.items,blocks.header,blocks.cells,blocks.accordion.items,blocks.timeline.items,blocks.links.image",
        method: "get",
      });
      if (res.data.data.length) {
        this.componentList = res.data.data[0].attributes.blocks;
        console.log(this.componentList[1]);
      }
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/css/mixins.scss";
.contain {
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: relative !important;
}
.cards-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
</style>
