<template>
  <a style="all: unset; padding-bottom: 1rem" :href="link" target="blank">
    <b-card
      :title="title?.replace(/\n/g, '')"
      :img-src="image?.data?.attributes?.url"
      :img-top="type == 'img-top'"
      :img-left="type == 'img-left'"
      :img-right="type == 'img-right'"
      :img-bottom="type == 'img-bottom'"
      img-height="200"
      img-width="200"
      :style="{ background: backgroundColor, cursor: link ? 'pointer' : 'default' }"
    >
      <b-card-text>
        {{ text }}
      </b-card-text>
      <br />
    </b-card>
  </a>
</template>

<script>
import { BCard } from "bootstrap-vue-3";

export default {
  name: "CardCmp",
  props: {
    backgroundColor: String,
    title: String,
    image: Object,
    text: String,
    type: String,
    btnText: String,
    link: String,
  },
  computed: {},
  components: {
    BCard,
  },
  data() {},
};
</script>

<style scoped lang="scss">
@import "@/assets/css/mixins.scss";

.timeline {
  border-left: 1px solid hsl(0, 0%, 90%);
  position: relative;
  list-style: none;
}

.timeline .timeline-item {
  position: relative;
}

.timeline .timeline-item:after {
  position: absolute;
  display: block;
  top: 0;
}

.timeline .timeline-item:after {
  background-color: hsl(0, 0%, 90%);
  left: -38px;
  border-radius: 50%;
  height: 11px;
  width: 11px;
  content: "";
}
</style>
