<template>
  <slot name="default"></slot>
  <div class="container">
    <div class="content">
      <slot name="content"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "TheContainer",
  props: {},
  computed: {},
};
</script>

<style scoped lang="scss">
@import "@/assets/css/mixins.scss";

.container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  //min-height: 50vh;
}
.content {
  max-width: 1500px;
  width: 100%;
  padding: 0px 88px;
  padding-top: 88px;
  @include md {
    max-width: 800px;
    width: 100%;
    padding: 0px 40px;
    padding-top: 60px;
  }
  @include sm {
    // max-width: 450px;
    width: 100%;
    padding: 0px 20px;
    padding-top: 30px;
  }
}
</style>
