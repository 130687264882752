<template>
  <section class="py-5">
    <ul class="timeline">
      <template v-for="item in items" :key="item">
        <li class="timeline-item mb-5">
          <h5 class="fw-bold">{{ item.title }}</h5>
          <p class="text-muted mb-2 fw-bold">{{ item.subtitle }}</p>
          <p class="text-muted">
            {{ item.description }}
          </p>
        </li>
      </template>
    </ul>
  </section>
</template>

<script>
export default {
  name: "TimelineCmp",
  props: { items: Array },
  computed: {},
};
</script>

<style scoped lang="scss">
@import "@/assets/css/mixins.scss";

.timeline {
  border-left: 1px solid hsl(0, 0%, 90%);
  position: relative;
  list-style: none;
}

.timeline .timeline-item {
  position: relative;
}

.timeline .timeline-item:after {
  position: absolute;
  display: block;
  top: 0;
}

.timeline .timeline-item:after {
  background-color: hsl(0, 0%, 90%);
  left: -38px;
  border-radius: 50%;
  height: 11px;
  width: 11px;
  content: "";
}
</style>
