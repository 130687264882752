<template>
  <div class="image-container">
    <img :src="backgroundImage" alt="Background" />
    <OnClickOutside @trigger="reset">
      <div
        :class="'image-label-wrapper' + (index == 3 && inactive ? ' click' : '')"
        v-for="(label, index) in labels"
        :key="index"
        @click="clickChip(label.text)"
        :style="{
          top: label.top,
          left: label.left,
          width: label.width,
          height: label.height,
          opacity: label.opacity,
        }"
      >
        <div class="image-label">
          {{ label.name }}
        </div>
      </div>
    </OnClickOutside>
  </div>
</template>

<script>
import { OnClickOutside } from "@vueuse/components";

export default {
  components: {
    OnClickOutside,
  },
  props: {
    descriptionFull: {
      type: String,
      default: "",
    },
    lbls: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      backgroundImage: require("@/assets/images/schema.png"),
      descr: "",
      labels: [],
      downloadIcon: require("@/assets/svg/mouse_click.svg"),
      inactive: true,
    };
  },
  created() {
    this.descr = this.descriptionFull;
    this.labels = this.lbls;
  },
  mounted() {
    this.$nextTick(() => {
      this.updateLabels();
      window.addEventListener("resize", this.updateLabels);
    });
    this.$emit("description", { text: this.descr });
  },
  methods: {
    updateLabels() {
      if (this.$el.querySelector(".image-container") !== null) {
        const containerWidth = this.$el.querySelector(".image-container").offsetWidth;
        const containerHeight = this.$el.querySelector(".image-container").offsetHeight;

        this.labels.forEach((label) => {
          // Example calculations (customize as needed):
          // Resizing the label width and height based on container size.
          label.width = `${Math.max(50, containerWidth * 0.1)}px`; // Minimum 50px or 10% of container width
          label.height = `${Math.max(20, containerHeight * 0.05)}px`; // Minimum 20px or 5% of container height

          // Adjusting the position
          label.top = `${(parseFloat(label.top) * containerHeight) / 100}px`;
          label.left = `${(parseFloat(label.left) * containerWidth) / 100}px`;
        });
      }
    },
    clickChip(name) {
      this.inactive = false;
      this.labels.forEach((label) => {
        if (label.text !== name) label.opacity = "0.3";
        else {
          label.opacity = "1";
          this.$emit("description", { text: label.description });
        }
      });
    },
    reset() {
      this.labels.forEach((label) => {
        label.opacity = "1";
      });
      this.$emit("description", { text: this.descr });
    },
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.updateLabels);
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/css/mixins.scss";

.image-container {
  position: relative;
  /* Set other styles as needed, such as width and height */
  @include md() {
    max-width: 600px;
  }
}
.image-container img {
  width: 100%;
  height: auto;
}

.image-label-wrapper {
  margin: 10px;
  position: absolute;
  height: 100px;
  width: 300px;

  @include md() {
    height: 100px;
    width: 100px;
  }

  @include sm() {
    height: 50px;
    width: 50px;
  }
}
.click {
  animation: pulse-effect 2s infinite;
}

@keyframes pulse-effect {
  0% {
    transform: scale(0.9);
    //   box-shadow: 0 0 0 0 #ffffff;
  }

  70% {
    transform: scale(1);
    // box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
  }

  100% {
    transform: scale(0.9);
    // box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
  }
}
.image-label {
  position: absolute;
  /* Set other label styles as needed */
  cursor: pointer;
  z-index: 2;
  display: inline-flex;
  padding: 1px 9px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 24px;
  background: #aef448;
  color: #000;
  font-size: 1.2em;
  font-weight: 400;
  line-height: 30px; /* 125% */

  &:hover {
    background-color: #bef448;
  }
  @include md() {
    color: #000;
    /* typesetting */
    font-size: 14px;
    font-weight: 400;
    line-height: 16px; /* 162.5% */
    padding: 1px 5px;
    text-align: center;
  }
  @include sm() {
    font-size: 12px;
    line-height: 14px; /* 162.5% */
    padding: 1px 5px;
    text-align: center;
  }
}
</style>
