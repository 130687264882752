<template>
  <TheContainer>
    <template v-slot:content>
      <div class="main-collector" ref="back">
        <h2>
          {{ title }}
        </h2>
        <div class="collector-wrap">
          <div>
            <ImageWithLabels
              :description-full="descriptionFull"
              :lbls="labels"
              @description="upd"
            />
          </div>
          <div style="overflow-y: hidden; border-radius: 12px">
            <div class="description-top" v-html="markdownToHtml(description)"></div>
          </div>
        </div>
      </div>
    </template>
  </TheContainer>
</template>

<script>
import { marked } from "marked";
import TheContainer from "@/components/layout/TheContainer.vue";
import ImageWithLabels from "@/components/base/ImageWithLabels.vue";
export default {
  name: "CollectorPage",
  components: {
    TheContainer,
    ImageWithLabels,
  },
  props: {
    msg: String,
    pageData: Object,
  },
  data() {
    return {
      description: "",
      title: "",
      descriptionFull: "",

      labels: [
        {
          opacity: "1",
          top: "-2.2% ",
          left: "58.5%",
          text: "Data",
          description: "Data description",
        },
        {
          opacity: "1",
          top: "14.5%",
          left: "33%",
          text: "Worker",
          description: "Worker description",
        },
        {
          opacity: "1",
          top: "14.5%",
          left: "78%",
          text: "Correlator",
          description: "Correlator description",
        },
        {
          opacity: "1",
          top: "28%",
          left: "8.1%",
          text: "Balancer",
          description: "Balancer description",
        },
        {
          opacity: "1",
          top: "32.7%",
          left: "53.2%",
          text: "Master",
          description: "Master description",
        },
        {
          opacity: "1",
          top: "51%",
          left: "72.8%",
          text: "APM",
          description: "APM description",
        },
        {
          opacity: "1",
          top: "52.7%",
          left: "28%",
          text: "Collector",
          description: "Collector description",
        },
        {
          opacity: "1",
          top: "66.5%",
          left: "2.5%",
          text: "eventEmitter",
          description: "Source description",
        },
      ],
    };
  },
  mounted() {
    this.get();
    window.addEventListener("scroll", this.handleScroll);
  },
  unmounted() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      const scrolled = 100 - window.scrollY / 10;
      this.$refs.back.style = `background: linear-gradient(0deg, rgba(33, 165, 110, 0.5) 0%, rgb(223, 255, 158) ${scrolled}%, rgba(33, 165, 110, 0.5) 100%);`;
    },
    upd(v) {
      this.description = v.text;
      if (v.text.length == 0) this.description = this.descriptionFull;
    },
    shortText(t) {
      return t.slice(0, 250) + " ...";
    },
    replaceSpacesInCode(htmlString) {
      // Используем регулярное выражение для поиска содержимого внутри <code></code>
      // и заменяем пробелы на &nbsp; только внутри этих элементов
      return htmlString.replace(/<code>(.*?)<\/code>/g, function (match, p1) {
        return "<code>" + p1.replace(/ /g, "&nbsp;") + "</code>";
      });
    },

    markdownToHtml(t) {
      return this.replaceSpacesInCode(marked(t)).replaceAll(
        "<code>",
        "<code  style='padding: 1px 9px;color:black; border-radius: 24px; border: 1px solid black;'>"
      );
    },
    updateDescriptions(labels, data) {
      const mapping = {
        Data: data.data,
        Worker: data.worker,
        Correlator: data.correlator,
        Balancer: data.balancer,
        Master: data.master,
        APM: data.apm,
        Collector: data.collector,
        eventEmitter: data.eventEmitter,
      };

      // Update each label's description
      labels.forEach((label) => {
        if (mapping[label.text]) {
          label.description =
            mapping[label.text].description + mapping[label.text].fullDescription;
          label.name = mapping[label.text].name;
        }
      });

      return labels;
    },
    async get() {
      let pageData = this.pageData;
      this.title = pageData.eventsQueue.title;
      this.description = pageData.eventsQueue.fullDescription;
      this.descriptionFull = pageData.eventsQueue.fullDescription;
      this.labels = this.updateDescriptions(this.labels, pageData.eventsQueue);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/assets/css/mixins.scss";
h2 {
  text-align: left;
  color: #fff;
  font-size: 80px;
  font-style: normal;
  font-weight: 400;
  line-height: 90px; /* 112.5% */
  text-transform: uppercase;
  @include md {
    font-size: 40px;
    line-height: 50px;
  }

  @include sm {
    font-size: 28px;
    line-height: 30px;
  }
}
.main-collector {
  background: linear-gradient(90deg, rgba(33, 165, 110, 0.5) 0%, rgb(223, 255, 158) 100%);
  padding: 20px;
  border-radius: 12px;
}
.description-top {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  text-align: left;
  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;

  background: #ffffff;
  box-shadow: inset 0px 0px 2px rgba(0, 0, 0, 0.9);
  padding: 20px;
  border-radius: 12px;
  overflow-y: auto;
  height: 600px;

  @include md {
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 133.333% */
    height: 400px;
  }
  @include sm {
    font-size: 16px;
    line-height: 24px; /* 133.333% */
    margin-top: 20px;
    height: 400px;
  }
}
.collector-wrap {
  display: grid;
  grid-template-columns: 3fr 2fr;
  column-gap: 20px;
  align-items: center;
  @include md {
    grid-template-columns: 3fr 2fr;
  }
  @include sm {
    grid-template-columns: 1fr;
  }
}
</style>
