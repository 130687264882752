<template>
  <TheContainer>
    <template v-slot:content>
      <h2>{{ title }}</h2>
      <div class="customers-wrapper">
        <div class="customers-row">
          <template
            v-for="idx in Array.from(
              { length: Math.ceil(customers.length / 2) },
              (_, i) => 2 * i
            )"
            :key="idx"
          >
            <div style="display: flex; flex-direction: column; gap: 30px">
              <a :href="customers[idx].link" target="_blank">
                <img
                  :src="customers[idx].image?.data?.attributes.url"
                  class="customers-image"
                />
              </a>
              <a
                :href="customers[idx + 1].link"
                target="_blank"
                v-if="idx + 1 < customers.length"
              >
                <img
                  :src="customers[idx + 1].image?.data?.attributes.url"
                  class="customers-image"
                />
              </a>
            </div>
          </template>
        </div>
      </div>
    </template>
  </TheContainer>
</template>

<script>
import TheContainer from "@/components/layout/TheContainer.vue";
export default {
  name: "ParnersBlock",
  props: {
    msg: String,
    pageData: Object,
  },
  components: {
    TheContainer,
  },
  data() {
    return {
      customers: [],
      title: "",
    };
  },
  mounted() {
    this.get();
  },
  methods: {
    async get() {
      let pageData = this.pageData;
      this.customers = pageData.customers.items;
      this.title = pageData.customers.title;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/assets/css/mixins.scss";
h2 {
  text-align: left;
  color: #000;
  font-size: 80px;
  font-style: normal;
  font-weight: 400;
  line-height: 90px; /* 112.5% */
  text-transform: uppercase;
  @include md {
    font-size: 40px;
    line-height: 50px;
  }
  @include sm {
    font-size: 28px;
    line-height: 30px;
  }
}

.customers-row {
  display: flex;
  gap: 30px;
  margin-bottom: 30px;
  overflow-x: auto;
}
.customers-wrapper {
  display: flex;
  flex-direction: column;
  overflow-x: auto;
  align-items: start;
  row-gap: 60px;
  @include md {
    row-gap: 30px;
    overflow-x: auto;
  }
  @include sm {
    row-gap: 30px;
    overflow-x: auto;
  }
}

.customers-image {
  filter: grayscale(100%);
  width: 300px;
  height: 70px;
  object-fit: contain;
  @include sm {
    max-width: 100px;
    width: 100px;
    height: 30px;
    object-fit: contain;
    &:hover {
      transition: 0.3s;
      filter: grayscale(0%);
    }
  }
  &:hover {
    transition: 0.3s;
    filter: grayscale(0%);
  }
}
</style>
