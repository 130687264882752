<template>
  <div v-if="isOpen" class="modal-wrapper" :style="cssProps">
    <div ref="modal" class="dialog-content__group">
      <div :class="isImage ? 'dialog-image' : 'dialog-content'">
        <slot />
      </div>
      <div v-if="$slots.body1" class="dialog-content" :class="classes">
        <slot name="body1" />
      </div>
      <div v-if="$slots.btn" class="dialog-content" :class="classes">
        <slot name="btn" />
      </div>
      <template v-if="isFullWidth">
        <br />
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: "BaseModal",
  props: {
    /** Сделать модальное окно большого размера */
    big: Boolean,
    size: {
      type: [String, Number],
      default: 550,
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
    isImage: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isFullWidth: false,
    };
  },
  watch: {
    isOpen(currentVal) {
      const body = document.querySelector("html");
      if (currentVal) {
        body.classList.add("no-scroll");
      } else {
        let count = document.getElementsByClassName("modal-wrapper").length;
        if (count <= 1) body.classList.remove("no-scroll");
      }
    },
  },
  computed: {
    cssProps() {
      return this.isImage
        ? {}
        : {
            "--base-modal-size": this.size + "px",
          };
    },
  },
  methods: {
    updateSize() {
      this.$nextTick(() => {
        this.fullWidth();
      });
    },
    fullWidth() {
      if (this.$refs.modal !== null) {
        this.isFullWidth = false;
        this.$nextTick(() => {
          let modalHeight = this.$refs.modal.clientHeight,
            windowHeight = window.innerHeight;
          this.isFullWidth = modalHeight + 50 > windowHeight;
        });
      }
    },
    closeModal() {
      if (this.isPermanent) return;
      const body = document.querySelector("html");
      body.classList.remove("no-scroll");
      this.$emit("close");
    },
  },
  mounted() {
    window.onpopstate = function (event) {
      console.log(event);
      const body = document.querySelector("html");
      body.classList.remove("no-scroll");
    };
  },
  unmounted() {
    const body = document.querySelector("html");
    body.classList.remove("no-scroll");
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/css/mixins.scss";
.modal-wrapper {
  display: grid;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 10;
  max-height: 100vh;
  min-height: 100vh;
  zoom: 1.25;
  align-content: center;
  justify-content: space-around;
  overflow-x: auto;
  background-color: #0000009d;
  @include md {
    // align-content: start;
  }
  @include sm {
    // align-content: start;
  }
}

.dialog-content {
  z-index: 15;
  position: relative;
  background-color: #fff;
  padding: 30px;
  margin: 12px;
  border-radius: 24px;
  min-width: 350px;
  width: var(--base-modal-size);
  max-width: var(--base-modal-size);
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.15);
  @include sm {
    padding: 20px;
    margin: 10px;
    max-width: 300px;
  }
}
.dialog-image {
  position: relative;
  width: var(--base-modal-size);
  max-width: 80vw;
  max-height: 80vh;
  @include md {
    min-width: 100vw;
    padding: 20px;
    margin: 10px;
    max-width: 300px;
  }
  @include sm {
    min-width: 100vw;
    padding: 20px;
    margin: 10px;
    max-width: 300px;
  }
}
</style>
