<template>
  <div class="mmenu">
    <div class="mmenu__menu" ref="menu">
      <img class="close" src="@/assets/svg/cross.svg" />
      <router-link to="/">
        <img :src="mmenuIcon" class="mmenu-logo" />
      </router-link>

      <div class="mmenu__nav button" @click="emitContactModal">
        {{ this.translate["request"][this.lng] }}
        <img class="arrow" :src="arrowRight" />
      </div>
      <router-link class="mmenu__nav" to="/">{{
        this.data["aboutProduct"]?.name || ""
      }}</router-link>
      <ul>
        <template v-for="(item, idx) in submenu1" :key="idx"
          ><li>
            <a class="mmenu__nav" @click="$emit('close')" :href="'/' + item.url">{{
              item.name
            }}</a>
          </li>
        </template>
      </ul>
      <router-link class="mmenu__nav" to="/about">{{
        this.data["aboutCompany"]?.name || ""
      }}</router-link>
      <ul>
        <template v-for="(item, idx) in submenu2" :key="idx"
          ><li>
            <a class="mmenu__nav" @click="$emit('close')" :href="'/' + item.url">{{
              item.name
            }}</a>
          </li>
        </template>
      </ul>
      <router-link class="mmenu__nav" to="/presale">{{
        this.data["helpfulInfo"]?.name || ""
      }}</router-link>
      <ul>
        <template v-for="(item, idx) in submenu3" :key="idx"
          ><li>
            <a class="mmenu__nav" @click="$emit('close')" :href="'/' + item.url">{{
              item.name
            }}</a>
          </li>
        </template>
      </ul>
      <div class="mmenu__nav" @click="changelng(lng == 'ru' ? 'en' : 'ru')">
        {{ lng == "ru" ? "eng" : "rus" }}
      </div>
    </div>
  </div>
</template>

<script>
import translate from "@/utils/translate";
import { getCurrentLocale, setCurrentLocale } from "@/utils/current-locale";
export default {
  name: "MobileMenu",
  data() {
    return {
      mmenuIcon: require("@/assets/svg/footer-icon.svg"),
      arrowRight: require("@/assets/svg/arrow-right-white.svg"),
      lng: "",
      translate,
    };
  },
  components: {},
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  computed: {},
  created() {
    this.lng = getCurrentLocale();
    this.submenu1 = this.data["aboutProduct"].links;
    this.submenu2 = this.data["aboutCompany"].links;
    this.submenu3 = this.data["helpfulInfo"].links;
  },
  methods: {
    emitContactModal() {
      this.isOpen = false;
      this.$emit("contact");
    },
    changelng(lng) {
      setCurrentLocale(lng);
      this.lng = lng;
      location.reload();
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/css/mixins.scss";

.mmenu {
  display: grid;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 100;
  max-height: 100vh;
  min-height: 100vh;
  zoom: 1.25;
  justify-content: flex-start;
  overflow-x: auto;
  background-color: white;
}
.mmenu__menu {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  max-width: 800px;
  width: 100%;
  padding: 40px 40px;
  overflow: auto;
  height: 100%;
  @include sm {
    padding: 20px 20px;
  }
}
.mmenu__sub {
  display: flex;
  min-width: 33%;
  gap: 32px;
  &.end {
    justify-content: end;
  }
  &.start {
    display: flex;
  }
}

.mmenu__nav {
  cursor: pointer;
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
  text-decoration: none;
  padding: 16px;
  @include md {
    font-size: 13px;
  }
}
.button {
  background: #21a56e;
  color: white;
  border-radius: 12px;
  &:hover {
    background: #21a56e;
  }
}
.arrow {
  padding-left: 12px;
}
.active-chip {
  position: relative;
  display: block;
  line-height: 15px;
  text-align: center;
  color: #21a56e;
  font-size: 30px;
}
.mmenu-logo {
  display: flex;
  margin-bottom: 24px;
}
.close {
  height: 20px;
  width: 20px;
  position: absolute;
  top: 40px;
  right: 40px;
  opacity: 0.15;
  cursor: pointer;
  @include sm {
    top: 20px;
    right: 20px;
  }
}
li {
  margin: 5px 0px;
}
</style>
