<template>
  <base-modal size="700" :is-open="isOpen" @close="closeModal">
    <img class="close" src="@/assets/svg/cross.svg" @click="closeModal" />
    <OnClickOutside @trigger="closeModal">
      <div class="demo-title">{{ title }}</div>
      <div class="demo-form">
        <form @submit.prevent="submitForm">
          <div class="demo-item">
            <label for="fullName">{{ this.translate["form_name"][this.lng] }}*</label>
            <input
              type="text"
              id="fullName"
              v-model="form.fullName"
              :class="{ 'invalid-field': !isValid.fullName && form.fullName.length > 0 }"
              required
            />
          </div>
          <div class="demo-item">
            <label for="company">{{ this.translate["form_company"][this.lng] }}*</label>
            <input
              type="text"
              id="company"
              v-model="form.company"
              :class="{ 'invalid-field': !isValid.company && form.company.length > 0 }"
              required
            />
          </div>
          <div class="contact-pair">
            <div class="demo-item">
              <label for="email">{{ this.translate["form_email"][this.lng] }}*</label>
              <input
                type="email"
                id="email"
                v-model="form.email"
                required
                :class="{ 'invalid-field': !isValid.email && form.email.length > 0 }"
              />
            </div>
            <div class="demo-item">
              <label for="phone">{{ this.translate["form_phone"][this.lng] }}*</label>
              <input
                type="tel"
                id="phone"
                v-model="form.phone"
                required
                :class="{ 'invalid-field': !isValid.phone && form.phone.length > 0 }"
              />
            </div>
          </div>
          <div class="demo-item">
            <label for="comment">{{ this.translate["form_comment"][this.lng] }}</label>
            <textarea type="tel" id="comment" v-model="form.comment" />
          </div>

          <p class="demo-additional" style="padding: 10px 0px" v-if="isPrice">
            {{ this.translate["form_disclaimer_price"][this.lng] }}
          </p>

          <p class="demo-additional" style="padding: 10px 0px">
            {{ this.translate["form_disclaimer"][this.lng] }}
          </p>
          <div class="demo-item" style="align-items: center">
            <div
              type="submit"
              class="demo-button"
              :class="{
                'disabled-button': !Object.values(this.isValid).every(Boolean),
              }"
              @click="submitForm"
            >
              {{ this.translate["form_send"][this.lng] }}
            </div>
          </div>
        </form>
      </div>
    </OnClickOutside>
  </base-modal>
</template>

<script>
import BaseModal from "./BaseModal";
import { OnClickOutside } from "@vueuse/components";
import ApiUtil from "@/utils/api";
import translate from "@/utils/translate";
import { getCurrentLocale } from "@/utils/current-locale";
export default {
  name: "ContactSupportModal",
  components: { BaseModal, OnClickOutside },
  data() {
    return {
      crossSvg: require("@/assets/svg/cross.svg"),
      isOpen: false,
      translate,
      lng: "",
      form: {
        fullName: "",
        company: "",
        email: "",
        phone: "",
        comment: "",
      },
      isValid: {
        fullName: false,
        company: false,
        email: false,
        phone: false,
      },
      disabled: true,
      title: "",
    };
  },

  props: {},
  watch: {
    "form.fullName"(currentVal) {
      this.isValid.fullName = currentVal.length > 0;
      console.log("fullName", Object.values(this.isValid).every(Boolean), this.isValid);
      // this.disabled = !Object.values(this.isValid).every(Boolean);
    },
    "form.company"(currentVal) {
      this.isValid.company = currentVal.length > 0;
      console.log("company", Object.values(this.isValid).every(Boolean), this.isValid);
      // this.disabled = !Object.values(this.isValid).every(Boolean);
    },
    "form.email"(currentVal) {
      this.isValid.email = Boolean(
        this.validateEmail(currentVal) & (currentVal.length > 0)
      );
      console.log("email", Object.values(this.isValid).every(Boolean), this.isValid);
      // this.disabled = !Object.values(this.isValid).every(Boolean);
    },
    "form.phone"(currentVal) {
      this.isValid.phone = Boolean(
        this.validatePhone(currentVal) & (currentVal.length > 0)
      );
      console.log("phone", Object.values(this.isValid).every(Boolean), this.isValid);
      // this.disabled = !Object.values(this.isValid).every(Boolean);
    },
  },
  created() {
    this.lng = getCurrentLocale();
  },
  methods: {
    showModal(type) {
      this.isPrice = type == "price";
      const tr = {
        price: this.translate["price"][this.lng],
        demo: this.translate["request"][this.lng],
        partner: this.translate["partner"][this.lng],
      };
      this.title = tr[type];
      this.isOpen = true;
      this.form = {
        fullName: "",
        company: "",
        email: "",
        phone: "",
        comment: "",
      };
    },
    closeModal() {
      this.isOpen = false;
    },
    validateEmail(email) {
      const re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
      return re.test(email);
    },
    validatePhone(phone) {
      const re = /^\+?(\d{1,3})?[-\s.]?(\(\d{1,3}\)|\d{1,3})[-\s.]?(\d{1,4}[-\s.]?){2,3}\d{1,4}$/;
      return re.test(phone);
    },
    async submitForm() {
      this.isValid.fullName = this.form.fullName.length > 0;
      this.isValid.company = this.form.company.length > 0;
      this.isValid.email = this.validateEmail(this.form.email);
      this.isValid.phone = this.validatePhone(this.form.phone);

      if (Object.values(this.isValid).every(Boolean)) {
        const formData = {
          data: {
            Status: "Новый",
            sender: {
              fullName: this.form.fullName,
              company: this.form.company,
            },
            contacts: {
              email: this.form.email,
              phone: this.form.phone,
            },
            message: this.form.comment,
          },
        };
        await ApiUtil({
          url: "lidies",
          method: "post",
          data: formData,
        });
        this.isOpen = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/css/mixins.scss";
.close {
  position: absolute;
  right: 25px;
  top: 25px;
  opacity: 0.15;
  cursor: pointer;
  @include sm {
    height: 20px;
    width: 20px;
    right: 20px;
    top: 20px;
  }
}
.demo-title {
  color: #000;
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 50% */
  text-align: center;
  padding-bottom: 21px;
  @include md {
    font-size: 30px;
  }
  @include sm {
    font-size: 30px;
    text-align: left;
  }
}
.demo-form {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}
.demo-item {
  width: 100%;
  padding-top: 20px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  color: #000;
  /* H3 */
  font-size: 16px;
  font-style: 400;
  font-weight: 400;
  line-height: 26px; /* 162.5% */
  text-transform: uppercase;
  @include md {
    padding-top: 10px;
    font-size: 14px;
  }
  @include sm {
    padding-top: 5px;
  }
}
.demo-additional {
  color: #000;
  font-family: RobotoLight;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 100% */
  opacity: 0.4;
  @include md {
    font-size: 16px;
  }
  @include sm {
    font-size: 14px;
    line-height: 18px; /* 100% */
    opacity: 0.7;
  }
}

.demo-button {
  width: 100%;
  display: flex;
  width: 343px;
  padding: 16px 36px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 16px;
  background: #21a56d;
  color: white;
  @include md {
    width: 243px;
    padding: 8px 18px;
  }
  @include sm {
    width: 220px;
    padding: 10px 16px;
  }
}

.contact-pair {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
  @include sm {
    flex-direction: column;
  }
}
.invalid-field {
  border: 1px solid #dc1d1d;
}

.disabled-button {
  background-color: grey;
}

input {
  border-radius: 4px;
  padding: 15px 24px 15px 24px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  height: 46px;
  color: #000;
  /* typesetting */
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px; /* 162.5% */
  @include md {
    height: 40px;
    font-size: 14px;
    line-height: 20px;
    padding: 5px 12px 5px 12px;
  }

  @include sm {
    height: 40px;
    font-size: 12px;
    padding: 5px;
  }
}

textarea {
  border-radius: 4px;
  padding: 15px 24px 15px 24px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  height: 70px;
  color: #000;
  /* typesetting */
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px; /* 162.5% */
  @include md {
    height: 70px;
    font-size: 14px;
    line-height: 20px;
    padding: 5px 12px 5px 12px;
  }
  @include sm {
    height: 90px;
    font-size: 12px;
    padding: 5px;
  }
}
</style>
