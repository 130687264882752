/**
 * Static translation dictionary
 * @type { Record<string, { en: string, ru: string }> }
 */
const translate = {
    "header_lang": {"en": "ENG", "ru": "RUS"},
    "product": { "en": "About a product", "ru": "О продукте" },
    "about": { "en": "About us", "ru": "О компании" },
    "info": { "en": "Helpful information", "ru": "Полезная информация" },
    "news": { "en": "News", "ru": "Новости" },
    "request": { "en": "Request a demo", "ru": "Запросить демо" },
    "partner": { "en": "Become a partner", "ru": "Стать партнером" },
    "price": { "en": "Request a price", "ru": "Запросить стоимость" },
    "tech": { "en": "Technical Support Portal", "ru": "Портал тех.поддержки" },
    "docs": { "en": "Documentation", "ru": "Документация" },
    "form_name": { "en": "Full name", "ru": "Ваше ФИО" },
    "form_company": { "en": "Company", "ru": "Компания" },
    "form_comment": { "en": "Comment", "ru": "Комментарий" },
    "form_phone": { "en": "Phone", "ru": "Номер телефона" },
    "form_email": { "en": "Email", "ru": "Электронная почта" },
    "form_disclaimer": { "en": "The form involves entering corporate data. Pangeo Radar does not store or process personal data", "ru": "Форма предполагает ввод корпоративных данных. Компания Пангео Радар не хранит и не обрабатывает персональные данные " },
    "form_disclaimer_price": { "en": "The cost of the Radar platform is determined based on the data from the questionnaire and depends on the number of events processed per second and the complexity of the resulting architecture.", "ru": "Стоимость платформы Радар определяется на основании данных опросного листа и зависит от количества обрабатываемых в секунду событий и сложности итоговой архитектуры." },
    "form_send": { "en": "Send", "ru": "Отправить" },
    "events_title": { "en": "Events", "ru": "Мероприятия" },
    "news_title": { "en": "News", "ru": "Новости" },
    "news_empty": { "en": "No news yet ...", "ru": "Новостей пока нет..." },
    "events_empty": { "en": "No events yet ...", "ru": "Мероприятий пока нет..." },

    "sub_1_1": { "en": "About the product", "ru": "О продукте" },
    "sub_1_2": { "en": "Architecture", "ru": "Aрхитектура" },
    "sub_1_3": { "en": "Update history", "ru": "История обновлений" },
    "sub_1_4": { "en": "Licensing", "ru": "Лицензирование" },
    "sub_1_5": { "en": "Key features", "ru": "Ключевые особенности" },
    "sub_1_6": { "en": "Expertise", "ru": "Экспертиза" },
    "sub_2_1": { "en": "History", "ru": "История" },
    "sub_2_2": { "en": "News", "ru": "Новости" },
    "sub_2_2_1": { "en": "Events", "ru": "Мероприятия" },
    "sub_2_3": { "en": "Contacts", "ru": "Контакты" },
    "sub_2_4": { "en": "Vacancies", "ru": "Вакансии" },
    "sub_2_5": { "en": "Partners", "ru": "Партнеры" },
    "sub_3_1": { "en": "Compliance with policies", "ru": "Соответствие политикам" },
    "sub_3_2": { "en": "Licenses and certificates", "ru": "Лицензии и сертификаты" },
    "sub_3_3": { "en": "Support", "ru": "Поддержка" },
    "sub_3_4": { "en": "Training", "ru": "Обучение" },
    "sub_3_5": { "en": "Documentation and materials", "ru": "Документация и материалы" },

};

export default translate;
