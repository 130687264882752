<template>
  <router-link class="news-link" :to="`/news/${id}`">
    <div
      class="news"
      :style="{ 'grid-template-columns': imageSrc.length ? '4fr 1fr' : '1fr' }"
    >
      <div class="newstitle">
        <h3>
          {{ title }}
        </h3>
        <p class="date">{{ date }}</p>
      </div>
      <div v-if="imageSrc.length">
        <img class="newsimage" :src="imageSrc" />
      </div>
    </div>
  </router-link>
</template>

<script>
export default {
  name: "BaseNewsTtitle",
  props: {
    id: String,
    title: String,
    date: String,
    imageSrc: String,
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/css/mixins.scss";
.news-link {
  cursor: pointer;
  color: #000;
  line-height: normal;
  text-transform: uppercase;
  text-decoration: none;
  display: flex;
  &:hover {
    color: #000 !important;
  }
}

h3 {
  font-size: 3rem;
  font-style: normal;
  font-weight: 400;
  line-height: 64px; /* 133.333% */
  text-transform: uppercase;
  @include md {
    font-size: 24px;
    line-height: 32px; /* 133.333% */
  }
  @include sm {
    font-size: 18px;
    line-height: 22px;
  }
}

.newstitle {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.date {
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px; /* 144.444% */
  @include md {
    font-size: 16px;
    line-height: 24px; /* 144.444% */
  }
  @include sm {
    font-size: 12px;
    line-height: 14px;
  }
}
.news {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 3fr;
  padding: 48px 0px;
  column-gap: 60px;
  cursor: pointer;
  border-top: 1px solid #00000011;
  @include md {
    padding: 24px 0px;
    column-gap: 30px;
  }
  @include sm {
    padding: 12px 0px;
    column-gap: 20px;
  }
}
.newsimage {
  max-width: 400px;
  max-height: 200px;
  padding-left: 10px;
  object-fit: cover;
  @include md {
    width: 300px;
    height: 150px;
    object-fit: cover;
  }
  @include sm {
    width: 150px;
    height: 75px;
    object-fit: cover;
  }
}
</style>
