<template>
  <div>
    <b-table striped :items="trueItems" :fields="trueFields"></b-table>
  </div>
</template>

<script>
import { BTable } from "bootstrap-vue-3";

export default {
  name: "TableCmp",
  props: {
    items: Array,
    rows: Array,
    header: Array,
  },
  computed: {
    trueItems() {
      return (
        this.rows?.map((row) => {
          let item = {};
          row.cells.forEach((cell, idx) => {
            const headerItem = this.header[idx];
            if (headerItem) {
              const key = this.generateKeyFromValue(headerItem.value);
              item[key] = cell.value.trim();
            }
          });
          return item;
        }) || []
      );
    },
    trueFields() {
      return this.header?.map((h) => ({
        key: this.generateKeyFromValue(h.value),
        label: h.value,
      }));
    },
  },
  components: {
    BTable,
  },
  data() {},
  methods: {
    generateKeyFromValue(value) {
      // Convert the header value to a suitable key for items object
      return value.toLowerCase().replace(/\s+/g, "_");
    },
    splitArrayIntoNParts(array, n) {
      const result = [];
      const partSize = Math.ceil(array.length / n);

      for (let i = 0; i < n; i++) {
        const start = i * partSize;
        const end = start + partSize;
        result.push(array.slice(start, end));
      }

      return result;
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/css/mixins.scss";

.timeline {
  border-left: 1px solid hsl(0, 0%, 90%);
  position: relative;
  list-style: none;
}

.timeline .timeline-item {
  position: relative;
}

.timeline .timeline-item:after {
  position: absolute;
  display: block;
  top: 0;
}

.timeline .timeline-item:after {
  background-color: hsl(0, 0%, 90%);
  left: -38px;
  border-radius: 50%;
  height: 11px;
  width: 11px;
  content: "";
}
</style>
