<template>
  <header class="header" ref="header">
    <div class="header__menu" ref="menu">
      <div class="mobile-menu" @click="isOpen = true">
        <img src="@/assets/svg/mobile-menu.svg" />
      </div>
      <div>
        <router-link class="header__nav" to="/">
          <img :src="headerIcon" class="header-logo" />
        </router-link>
      </div>
      <div class="header__sub start">
        <router-link
          replace
          class="header__nav"
          to="/"
          @mouseover="
            sleep(200).then(() => {
              submenu = submenu1;
              hover = true;
            })
          "
          >{{ this.data["aboutProduct"]?.name || "" }}
          <div class="active-chip" v-if="$route.path == '/'">•</div></router-link
        >

        <router-link
          replace
          class="header__nav"
          to="/about"
          @mouseover="
            sleep(200).then(() => {
              submenu = submenu2;
              hover = true;
            })
          "
          >{{ this.data["aboutCompany"]?.name || "" }}
          <div class="active-chip" v-if="$route.path == '/about'">•</div></router-link
        >
        <router-link
          replace
          class="header__nav"
          to="/presale"
          @mouseover="
            sleep(200).then(() => {
              submenu = submenu3;
              hover = true;
            })
          "
          >{{ this.data["helpfulInfo"]?.name || "" }}
          <div class="active-chip" v-if="$route.path == '/presale'">•</div></router-link
        >

        <div
          class="header__nav button"
          @click="openContactModal"
          @mouseover="hover = false"
        >
          {{ this.translate["request"][this.lng] }}
        </div>
      </div>

      <div class="header__sub end">
        <div
          class="header__nav"
          @click="changelng(lng == 'ru' ? 'en' : 'ru')"
          @mouseover="hover = false"
        >
          {{ lng == "ru" ? "eng" : "rus" }}
        </div>
      </div>


      <a class="skolkovo-link" href="https://sk.ru/" target="_blank">
        <img :src="skolkovoHeaderIcon" alt="Участник Сколково" />
      </a>
    </div>
    <contact-modal ref="contactModal" />
  </header>

  <mobile-menu
    v-if="isOpen"
    @click="isOpen = false"
    @contact="openContactModal"
    ref="mobileMenu"
    :data="data"
  />
  <Transition>
    <extra-menu v-if="hover" ref="mobileMenu" :submenu="submenu" @mouseleave="cls" />
  </Transition>
</template>

<script>
import ContactModal from "@/components/base/ContactModal.vue";
import MobileMenu from "@/components/base/MobileMenu.vue";
import ExtraMenu from "@/components/base/ExtraMenu.vue";
import translate from "@/utils/translate";
import ApiUtil from "@/utils/api";
import { getCurrentLocale, setCurrentLocale } from "@/utils/current-locale";
export default {
  name: "TheHeader",
  data() {
    return {
      headerIcon: require("@/assets/svg/footer-icon.svg"),
      arrowRight: require("@/assets/svg/arrow-right.svg"),
      skolkovoHeaderIcon: require("@/assets/svg/sk-member-header.svg"),
      isOpen: false,
      lng: "",
      translate,
      contactTitle: "",
      hover: false,
      submenu: [],
      data: {},
      debounceTimer: null,
    };
  },
  components: {
    ContactModal,
    MobileMenu,
    ExtraMenu,
  },
  props: {},
  computed: {},
  watch: {
    $route(to, from) {
      console.log(to, from);
      this.hover = false;
      // const body = document.querySelector("html");
      // body.classList.remove("no-scroll");
    },
    hover(newValue) {
      console.log(newValue);
      //  const body = document.querySelector("html");
      if (newValue) {
        this.$refs.menu.style.background = `white`;
        this.$refs.header.style.background = `white`;
        //  body.classList.add("no-scroll");
      } else {
        const scrolled = window.scrollY;
        if (scrolled < 50) {
          this.$refs.menu.style.background = `none`;
          this.$refs.header.style.background = `none`;
        }
      }
    },
  },
  methods: {
    openContactModal() {
      this.$refs.contactModal.showModal("demo");
    },
    handleScroll() {
      clearTimeout(this.debounceTimer);
      this.debounceTimer = setTimeout(() => {
        const scrolled = window.scrollY;
        if (scrolled > 50 || this.hover) {
          this.$refs.menu.style.background = `white`;
          this.$refs.header.style.background = `white`;
        } else {
          this.$refs.menu.style.background = `none`;
          this.$refs.header.style.background = `none`;
        }
      }, 10); // Adjust debounce time as necessary
    },
    changelng(lng) {
      setCurrentLocale(lng);
      this.lng = lng;
      location.reload();
    },
    cls(event) {
      //const body = document.querySelector("html");
      //body.classList.remove("no-scroll");
      if (event.clientY > 100) {
        this.hover = false;
      }
    },
    async get() {
      let populate = ["aboutProduct.links", "aboutCompany.links", "helpfulInfo.links"];
      let res = await ApiUtil({
        url: "shapka/?populate=" + populate.join(","),
        method: "get",
      });
      this.data = res.data.data.attributes;
    },
    sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
  },
  async created() {
    this.lng = getCurrentLocale();
    await this.get();

    this.contactTitle = this.translate["request"][this.lng];
    this.submenu1 = this.data["aboutProduct"].links;
    this.submenu2 = this.data["aboutCompany"].links;
    this.submenu3 = this.data["helpfulInfo"].links;
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  unmounted() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/css/mixins.scss";

.header {
  position: fixed;
  display: flex;
  z-index: 4;
  justify-content: space-around;
  width: 100%;
  align-items: center;
  @include md {
    justify-content: space-around;
  }
}
.header__menu {
  display: grid;
  grid-template-columns: 1fr 5fr 1fr 1fr;
  align-content: middle;
  align-items: center;
  vertical-align: middle;
  justify-content: space-between;
  width: 100%;
  max-width: 1500px;
  padding: 10px 88px;
  border-radius: 0px 0px 8px 8px;
  @include md {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    text-align: center;
    max-width: 800px;
    padding: 10px 40px;
  }
  @include sm {
    max-width: 700px;
    padding: 10px 20px;
    display: flex;
  }
}
.header__sub {
  display: flex;
  justify-content: space-around;
  gap: 32px;
  &.end {
    justify-content: end;
    @include sm {
      display: none;
    }
  }
  &.start {
    display: flex;
    @include md {
      display: none;
    }
    @include sm {
      display: none;
    }
  }
}
.mobile-menu {
  display: none;
  cursor: pointer;
  padding: 20px;
  padding-left: 0px;
  @include md {
    display: flex;
  }
  @include sm {
    display: flex;
  }
}
.header__nav {
  cursor: pointer;
  color: #000;
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
  text-decoration: none;
  //height: 30px;
  padding: 5px 10px;
  @include md {
    font-size: 13px;
  }
}
.button {
  height: fit-content;
  background: #21a56e;
  color: white;
  border-radius: 12px;
  &:hover {
    transition: 0.3s;
    background: #2bd38d;
  }
}
.arrow {
  padding-left: 12px;
}
.active-chip {
  position: relative;
  display: block;
  line-height: 15px;
  text-align: center;
  color: #21a56e;
  font-size: 30px;
}
.header-logo {
  height: 50px;
  @include md {
    height: 40px;
    width: auto;
  }
  @include sm {
    height: 40px;
    width: auto;
  }
}

.modal-wrapper {
  display: grid;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 3;
  max-height: 100vh;
  min-height: 100vh;
  zoom: 1.25;
  align-content: center;
  justify-content: space-around;
  overflow-x: auto;
  background-color: #0000009d;
  @include md {
    // align-content: start;
  }
  @include sm {
    // align-content: start;
  }
}

.v-enter-active {
  transition-duration: 0.2s;
}

.v-leave-active {
  //transition: opacity 0.1s ease;
  transition-duration: 0.2s;
}

.v-enter-from {
  transform: translateY(-30px);
}
.v-leave-to {
  transform: translateY(-30px);
  opacity: 0;
}

.skolkovo-link {
  & > img {
    display: block;
    aspect-ratio: 34/10;
    height: 48px;

    margin-left: 16px;

    @include md {
      height: 40px;
      margin-left: 0;
    }
    @include sm {
      height: 40px;
      margin-left: 0;
    }
  }
}
</style>
