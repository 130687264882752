<template>
  <div class="menu-wrapper">
    <div class="mmenu">
      <div class="mmenu__menu" ref="menu">
        <template v-for="item in sub" :key="item">
          <a class="mmenu__nav" @click="$emit('close')" :href="'/' + item.url">{{
            item.name
          }}</a>
        </template>
      </div>
      <div></div>
    </div>
  </div>
</template>

<script>
import translate from "@/utils/translate";
import { getCurrentLocale, setCurrentLocale } from "@/utils/current-locale";
export default {
  name: "MobileMenu",
  data() {
    return {
      mmenuIcon: require("@/assets/svg/footer-icon.svg"),
      arrowRight: require("@/assets/svg/arrow-right.svg"),
      language: "",
      sub: [],
      translate,
    };
  },
  components: {},
  props: {
    submenu: {
      type: Array,
      default: () => [],
    },
  },
  computed: {},
  created() {
    this.lng = getCurrentLocale();
    this.sub = this.submenu;
  },
  watch: {
    submenu(currValue) {
      this.sub = [...currValue];
    },
  },
  methods: {
    emitContactModal() {
      this.isOpen = false;
      this.$emit("contact");
    },
    changeLanguage(language) {
      setCurrentLocale(language);
      this.lng = language;
      location.reload();
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/css/mixins.scss";

.menu-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-around;
}
.mmenu {
  //  border-top: 1px solid#21a56e60;
  width: 100%;
  display: flex;
  justify-content: center;
  position: fixed;
  z-index: 4;
  max-height: 130px;
  min-height: 130px;
  overflow-x: auto;
  background-color: white;

  padding: 10px 150px;
  margin-top: 70px;

  @include md {
    display: none;
  }
  @include sm {
    display: none;
  }
}

.mmenu__menu {
  position: relative;
  display: grid;
  width: 900px;
  grid-template-columns: 1fr 1fr;
  justify-content: flex-start;
  background: white;
  @include sm {
    padding: 20px 20px;
  }
}
.mmenu__sub {
  display: flex;
  min-width: 33%;
  gap: 32px;
  &.end {
    justify-content: end;
  }
  &.start {
    display: flex;
  }
}

.mmenu__nav {
  cursor: pointer;
  color: #000;
  text-decoration: none;
  font-size: 14px;
  text-transform: uppercase;
  padding: 10px 0px;
  @include md {
    font-size: 13px;
  }
  &:hover {
    color: #21a56e;
  }
}
.arrow {
  padding-left: 12px;
}
.active-chip {
  position: relative;
  display: block;
  line-height: 15px;
  text-align: center;
  color: #21a56e;
  font-size: 30px;
}
.mmenu-logo {
  display: flex;
  margin-bottom: 24px;
}
.close {
  height: 20px;
  width: 20px;
  position: absolute;
  top: 40px;
  right: 40px;
  opacity: 0.15;
  cursor: pointer;
  @include sm {
    top: 20px;
    right: 20px;
  }
}
</style>
