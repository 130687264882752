<template>
  <div class="ellipse-1"></div>
  <div class="ellipse-2"></div>
  <div class="wrapper"><div class="pic-corner"></div></div>
  <TheContainer>
    <template v-slot:content>
      <div class="title">
        <h1 style="padding-bottom: 24px">{{ data.title }}</h1>
        <div class="text1">
          {{ data.description }}
        </div>
        <div class="text2">{{ data.legalInformation?.title }}</div>
        <div class="text3">
          {{ data.legalInformation?.mainText }}
        </div>
        <div class="text4">
          {{ data.legalInformation?.tagline.content }}
        </div>
        <div class="text3" v-html="markdownToHtml(data.legalInformation?.content)"></div>
      </div>
      <div ref="lisence" class="text2">{{ data.licenses?.title }}</div>
      <template v-for="(item, idx) in items" :key="idx">
        <a class="doclink" :href="item.document.data.attributes.url" target="_blank">
          {{ item.name
          }}<span> <img class="arrow" src="@/assets/svg/arrow-right-curved.svg" /></span>
        </a>
      </template>
    </template>
  </TheContainer>
</template>

<script>
import { marked } from "marked";
import ApiUtil from "@/utils/api";
import TheContainer from "@/components/layout/TheContainer.vue";
export default {
  name: "AboutView",
  components: {
    TheContainer,
  },
  data() {
    return {
      data: {},
      items: [],
      hash: "",
    };
  },
  created() {
    this.get();
  },
  mounted() {
    this.hash = this.$route.hash;
  },
  watch: {
    $route(to, from) {
      console.log(to, from);
      this.hash = this.$route.hash;
    },
    hash(val) {
      if (val) {
        var element = this.$refs[val.slice(1)];
        window.scrollTo(0, element.offsetTop);
      }
    },
  },
  methods: {
    async get() {
      let populate = [
        "legalInformation.tagline",
        "legalInformation.title",
        "legalInformation",
        "licenses.items.document",
      ];
      let res = await ApiUtil({
        url: "o-kompanii?populate=" + populate.join(","),
        method: "get",
      });
      this.data = res.data.data.attributes;
      this.items = res.data.data.attributes.licenses.items;
    },
    markdownToHtml(t) {
      try {
        return marked(t);
      } catch (err) {
        console.log(err);
      }
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/css/mixins.scss";
h1 {
  color: #000;
  font-size: 108px;
  font-weight: 400;
  line-height: 112px; /* 103.704% */
  text-transform: uppercase;
  margin: 0px;
  @include md {
    font-size: 55px;
    line-height: 60px; /* 103.704% */
  }
  @include sm {
    font-size: 35px;
    line-height: 40px; /* 98.148% */
  }
}

ul li {
  color: black;
  list-style-type: none;
}

ul li:before {
  float: left;
  margin: 0 0 0 -1em;
  content: "—";
}
li span {
  position: relative;
  left: 24px;
  @include sm {
    left: 12px;
  }
}
.title {
  padding-top: 42px;
  padding-bottom: 88px;
  @include md {
    padding-top: 22px;
    padding-bottom: 48px;
  }
  @include sm {
    padding-top: 40px;
    padding-bottom: 30px;
  }
}
.text1 {
  color: #000;
  font-size: 36px;
  font-weight: 400;
  padding-bottom: 160px;
  @include md {
    font-size: 26px;
    padding-bottom: 60px;
  }
  @include sm {
    font-size: 16px;
    padding-bottom: 20px;
  }
}

.text2 {
  color: #000;
  font-size: 44px;
  font-weight: 800;
  line-height: 90px; /* 204.545% */
  text-transform: uppercase;
  padding-bottom: 20px;
  @include md {
    font-size: 24px;
    line-height: 45px; /* 204.545% */
  }
  @include sm {
    font-size: 24px;
    line-height: 45px; /* 204.545% */
  }
}

.text3 {
  color: rgba(0, 0, 0, 0.9);
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  @include md {
    font-size: 16px;
    line-height: 20px;
  }
  @include sm {
    font-size: 16px;
    line-height: 20px;
  }
}

.text4 {
  color: #000;
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
  line-height: 56px;
  margin: 88px 0px;
  padding-left: 40px;
  margin-left: 40px;
  border-left: 6px solid #21a56d;
  @include md {
    margin: 40px 0px;
    font-size: 24px;
    line-height: 34px;
    padding-left: 20px;
    margin-left: 20px;
    border-left: 3px solid #21a56d;
  }
  @include sm {
    margin: 40px 0px;
    font-size: 24px;
    line-height: 34px;
    padding-left: 20px;
    margin-left: 20px;
    border-left: 3px solid #21a56d;
  }
}
.doclink {
  border-top: 1px solid #00000011;
  border-bottom: 1px solid #00000011;
  padding: 24px 0px;
  color: #21a56d;
  font-size: 24px;
  font-weight: 500;
  line-height: 32px; /* 133.333% */
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  cursor: pointer;
  @include md {
    padding: 12px 0px;
    font-size: 16px;
    line-height: 24px; /* 133.333% */
  }
  @include sm {
    padding: 12px 0px;
    font-size: 16px;
    line-height: 24px; /* 133.333% */
  }
}
.arrow {
  @include md {
    height: 20px;
    width: auto;
  }
}
.ellipse-1 {
  top: -300px;
  left: -700px;
  z-index: -1;
  position: absolute;
  width: 1052.308px;
  height: 537.846px;
  flex-shrink: 0;
  border-radius: 1052.308px;
  opacity: 0.3;
  background: #21a56d;
  filter: blur(122.44999694824219px);
  @include md {
    width: 792.308px;
  }
  @include sm {
    width: 292.308px;
  }
}
.ellipse-2 {
  top: -350px;
  left: 50px;
  z-index: -1;
  position: absolute;
  width: 800.473px;
  height: 381.349px;
  flex-shrink: 0;
  border-radius: 800.473px;
  background: #c1ff84;
  filter: blur(152.89999389648438px);
  @include md {
    width: 700.473px;
  }
  @include sm {
    width: 200.473px;
  }
}

.wrapper {
  width: 40rem;
  height: 40rem;
  right: 0px;
  top: -250px;
  position: absolute;
  overflow: hidden;
  @include md {
    width: 20rem;
    height: 20rem;
    top: -100px;
  }
  @include sm {
    width: 10rem;
    height: 10rem;
    top: -10px;
    right: 0px;
  }
}
.pic-corner {
  width: 100%;
  height: 100%;
  transform: rotate(7.761deg);
  background-image: url("~@/assets/images/about-corner.png");
  position: absolute;
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 0.5;
  z-index: -1;
}
</style>
