<template>
  <TheContainer ref="sopkaSection">
    <template v-slot:content>
      <div class="b-ellipses">
        <div class="b-ellipse-1" ref="b-ellipse1" />
        <div class="b-ellipse-2" ref="b-ellipse2" />
        <div class="b-ellipse-3" ref="b-ellipse3" />
        <div class="b-ellipse-4" ref="b-ellipse4" />
        <div class="b-ellipse-5" ref="b-ellipse5" />
      </div>
      <div class="point">
        <div class="text">
          {{ text }}
        </div>
      </div>
    </template>
  </TheContainer>
</template>

<script>
import TheContainer from "@/components/layout/TheContainer.vue";
export default {
  name: "SopkaPage",
  props: {
    msg: String,
    pageData: Object,
  },
  components: {
    TheContainer,
  },
  data() {
    return {
      top: 0,
      bottom: 0,
      delta: 0,
      text: "",
    };
  },

  mounted() {
    this.get();
    window.addEventListener("scroll", this.handleScrollEllipse);
  },
  unmounted() {
    window.removeEventListener("scroll", this.handleScrollEllipse);
  },
  methods: {
    handleScrollEllipse() {
      const userComponent = this.$refs.sopkaSection.$el;
      // FIXME: надо убедиться, что это правильно, была ошибка, что нет getBoundingClientRect в userComponent
      const rect = userComponent['nextElementSibling'].getBoundingClientRect();
      this.top = rect.top;
      this.bottom = rect.bottom;
      this.delta = this.bottom - this.top;
      this.scrollPosition = (this.delta - this.top) / this.delta;
      if (this.scrollPosition >= 0.1 && this.scrollPosition < 1) {
        for (let i = 1; i < 6; i++) {
          const scrolled = i * 15 - (45 + i * 15) * this.scrollPosition;
          this.$refs["b-ellipse" + i].style.transform = `rotate(${scrolled}deg)`;
        }
      }
      if (this.scrollPosition >= 1) {
        for (let i = 1; i < 6; i++) {
          const scrolled = i * 15 - (45 + i * 15);
          this.$refs["b-ellipse" + i].style.transform = `rotate(${scrolled}deg)`;
        }
      }
    },
    async get() {
      let pageData = this.pageData;
      this.text = pageData.ellipsesText.content;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/assets/css/mixins.scss";
.point {
  position: relative;
  display: flex;
  align-items: center;
  color: #000;
  width: 100%;
  font-size: 44px;
  font-weight: 400;
  text-align: center;
  justify-content: center;
  padding: 350px 0px;
  @include md {
    font-size: 24px;
    padding: 290px 20px;
  }

  @include sm {
    font-size: 18px;
    padding: 60px 20px;
  }
}

.text {
  width: 725px;
  @include md {
    width: 500px;
  }
  @include sm {
    width: 250px;
  }
}
.line {
  margin-top: 88px;
  width: 1000px;
  height: 0px;
  stroke-width: 2px;
  stroke: #000;
  opacity: 0.1;
  @include md {
    margin-top: 24px;
    width: 700px;
  }
  @include sm {
    width: 300px;
  }
}

.b-ellipses {
  top: 0px;
  width: 100%;
  flex-shrink: 0;
  display: flex;
  justify-content: space-around;
  padding-top: 52px;
  flex-shrink: 0;
  position: relative;
  z-index: -1;
  @include md {
    padding-top: 0px;
  }
}
.b-ellipse-1 {
  z-index: -1;
  width: 452.314px;
  height: 954.755px;
  transform: rotate(30deg);
  flex-shrink: 0;
  position: absolute;
  border-radius: 50%;
  border: 3px solid #0057ff;
  left: 35%;

  @include md {
    width: 307.746px;
    height: 670.002px;
  }
  @include sm {
    width: 107.746px;
    height: 270.002px;
    border: 1px solid #0057ff;
  }
}
.b-ellipse-2 {
  z-index: -1;
  width: 452.314px;
  height: 954.755px;
  transform: rotate(45deg);
  flex-shrink: 0;
  position: absolute;
  border-radius: 50%;
  border: 3px solid #0057ff;
  left: 35%;
  opacity: 0.8;
  @include md {
    width: 307.746px;
    height: 670.002px;
  }
  @include sm {
    width: 107.746px;
    height: 270.002px;
    border: 1px solid #0057ff;
  }
}
.b-ellipse-3 {
  z-index: -1;
  width: 452.314px;
  height: 954.755px;
  transform: rotate(60deg);
  flex-shrink: 0;
  position: absolute;
  border-radius: 50%;
  border: 3px solid #0057ff;
  left: 35%;
  opacity: 0.6;
  @include md {
    width: 307.746px;
    height: 670.002px;
  }
  @include sm {
    width: 107.746px;
    height: 270.002px;
    border: 1px solid #0057ff;
  }
}
.b-ellipse-4 {
  z-index: -1;
  width: 452.314px;
  height: 954.755px;
  transform: rotate(75deg);
  flex-shrink: 0;
  position: absolute;
  border-radius: 50%;
  border: 3px solid #0057ff;
  left: 35%;
  opacity: 0.3;
  @include md {
    width: 307.746px;
    height: 670.002px;
  }
  @include sm {
    width: 107.746px;
    height: 270.002px;
    border: 1px solid #0057ff;
  }
}
.b-ellipse-5 {
  z-index: -1;
  width: 452.314px;
  height: 954.755px;
  transform: rotate(90deg);
  flex-shrink: 0;
  position: absolute;
  border-radius: 50%;
  border: 3px solid #0057ff;
  left: 35%;
  opacity: 0.2;
  @include md {
    width: 307.746px;
    height: 670.002px;
  }
  @include sm {
    width: 107.746px;
    height: 270.002px;
    border: 1px solid #0057ff;
  }
}
</style>
