<template>
  <TheContainer>
    <template v-slot:content>
      <!--<div class="ellipse_container">
        <div class="ellipse-1"></div>
        <div class="ellipse-2"></div>
      </div>-->
      <div class="swipe">
        <h2>{{ title }}</h2>
        <swiper
          :slidesPerView="1"
          :breakpoints="{
            '500': {
              slidesPerView: 1,
              spaceBetween: 10,
            },
            '550': {
              slidesPerView: 1.3,
              spaceBetween: 10,
            },
            '1000': {
              slidesPerView: 1.3,
              spaceBetween: 10,
            },

            '1500': {
              slidesPerView: 1.6,
              spaceBetween: 10,
            },
          }"
          :centeredSlides="true"
          :spaceBetween="30"
          :loop="true"
          :autoplay="autoplay"
          :pagination="{
            clickable: true,
          }"
          :modules="modules"
        >
          <template v-for="(item, idx) in items" :key="idx">
            <swiper-slide class="slide" v-if="item?.image.data != null">
              <div class="image_container">
                <img
                  class="image"
                  :src="item.image.data.attributes.url"
                  @click="stop(items, idx)"
                />
              </div>
              <div class="scd_container">
                <div class="scd">
                  <h3>{{ item.name }}</h3>
                  <div class="point">
                    {{ item.description }}
                  </div>
                </div>
              </div></swiper-slide
            >
          </template>
        </swiper>
      </div>
    </template>
  </TheContainer>
  <photo-modal ref="photoModal" />
</template>

<script>
import TheContainer from "@/components/layout/TheContainer.vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import PhotoModal from "@/components/base/PhotoModal.vue";
import "swiper/css";
import "swiper/css/pagination";
import { Autoplay, Pagination } from "swiper/modules";
export default {
  name: "KeySlidePage",
  props: {
    msg: String,
    pageData: Object,
  },
  components: {
    TheContainer,
    Swiper,
    SwiperSlide,
    PhotoModal,
  },
  data() {
    return {
      items: [],
      title: [],
      autoplay: {
        delay: 4000,
        disableOnInteraction: true,
      },
    };
  },
  setup() {
    return {
      modules: [Autoplay, Pagination],
    };
  },
  created() {
    this.get();
  },
  methods: {
    stop(items, idx) {
      this.autoplay = false;
      this.$refs.photoModal.showModal(
        this.sortedItems(items, idx).map((el) => el.image.data),
        this.sortedItems(items, idx).map((el) => el.name + ". " + el.description)
      );
    },
    async get() {
      let pageData = this.pageData;
      this.items = pageData.keyFeatures.elements || [];
      this.title = pageData.keyFeatures.title || [];
    },
    sortedItems(items, idx) {
      // if (idx == 0) return items;
      return [...[items[idx]], ...items.slice(idx, items.length), ...items.slice(0, idx)];
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/assets/css/mixins.scss";
h2 {
  text-align: left;
  color: #fff;
  font-size: 80px;
  font-weight: 400;
  line-height: 90px; /* 112.5% */
  text-transform: uppercase;
  @include md {
    font-size: 40px;
    line-height: 50px;
  }
  @include sm {
    font-size: 28px;
    line-height: 30px;
  }
}

h3 {
  font-weight: 400;
  color: #000;
  font-size: 30px;
  font-style: normal;
  line-height: 32px; /* 133.333% */
  text-transform: uppercase;
  padding: 0px;
  margin: 0px;
  padding-bottom: 16px;
  @include md {
    font-size: 30px;
    line-height: 40px;
  }
  @include sm {
    font-size: 18px;
    line-height: 22px;
  }
}
.description {
  width: 700px;
  margin: 400px auto;
  @include md {
    width: 300px;
  }
}
.point {
  color: #000;
  font-size: 20px;
  font-weight: 400;
  max-width: 1000px;
  @include md {
    font-size: 16px;
    max-width: 400px;
  }
  @include sm {
    font-size: 16px;
    line-height: 20px;
    max-width: 300px;
  }
}

.line {
  margin-top: 88px;
  margin-bottom: 18px;
  width: 100%;
  height: 0px;
  stroke-width: 2px;
  stroke: #000;
  opacity: 0.1;
  @include md {
    margin-top: 24px;
    width: 700px;
  }
  @include sm {
    width: 300px;
  }
}
.image_container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding-bottom: auto;
}
.image {
  width: 100%;
  height: 300px;
  border-radius: 8px;
  border-radius: 20px;
  padding: 10px;
  padding-top: 10px;
  flex-shrink: 0;
  display: flex;
  justify-content: space-around;
  position: relative;
  object-fit: cover;
  @include md {
    width: 400px;
    height: 250px;
  }
  @include sm {
    width: 100%;
    height: 150px;
  }
}

.scd_container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  text-align: left;
  flex-direction: column;
  padding-top: 30px;
  @include sm {
    padding-top: 30px;
  }
}
.scd {
  width: 100%;
  @include md {
    width: 400px;
  }
  @include sm {
    width: 100%;
  }
}
.ellipse_container {
  height: 70rem;
  width: 100%;
  position: absolute;
  left: 0;
  z-index: -1;
}

.slide {
  background: rgba(255, 255, 255);
  //border: 2px solid rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  padding: 40px 20px;
  height: 700px;
  box-shadow: inset 0px 0px 2px rgba(0, 0, 0, 0.9);
  flex-direction: column;
  @include md {
    height: auto;
  }
  @include sm {
    height: auto;
  }
}
.ellipse-1 {
  top: 0px;
  left: 0px;
  z-index: -1;
  position: absolute;
  width: 1052.308px;
  height: 537.846px;
  flex-shrink: 0;
  border-radius: 1052.308px;
  opacity: 0.3;
  background: #21a56d;
  filter: blur(122.44999694824219px);
  @include md {
    width: 792.308px;
  }
  @include sm {
    width: 292.308px;
  }
}
.ellipse-2 {
  top: 0px;
  left: -30rem;
  z-index: -1;
  position: absolute;
  width: 800.473px;
  height: 381.349px;
  flex-shrink: 0;
  border-radius: 800.473px;
  background: #c1ff84;
  filter: blur(152.89999389648438px);
  @include md {
    width: 700.473px;
  }
  @include sm {
    width: 200.308px;
  }
}

.swipe {
  background: linear-gradient(0deg, rgb(223, 255, 158) 0%, rgba(33, 165, 110, 0.5) 100%);
  //background: rgba(33, 165, 110, 0.5);
  padding: 40px 20px;
  border-radius: 12px;
  @include sm {
    padding: 20px 10px;
  }
}
.filter {
  top: -100px;
  right: -100px;
  z-index: 10;
  position: absolute;
  width: 200px;
  height: 150%;
  flex-shrink: 0;
  background: #ffffff;
  filter: blur(10px);
  @include md {
    width: 20px;
  }
  @include sm {
    display: none;
  }
}
</style>
