import axios from 'axios'
import { VueAxios } from './setup'
import { getCurrentLocale } from "@/utils/current-locale";


const handleError = (error) => {
    return Promise.reject(error)
}
let params = {}
let timeout = 36000



const domain = location.hostname === 'localhost' ? 'cms.pangeoradar.ru' : `cms.${location.hostname.replace(/^www\./, '')}`;
//const domain = 'cms.pangeoradar.ru'

const serviceMain = axios.create({
    baseURL: `https://${domain}/api/`,
    params: params,
    timeout: timeout,
    headers: {
        'Content-Type': 'application/json',
    },
})


serviceMain.interceptors.request.use((config) => {
    config.params['locale'] = getCurrentLocale();
    return config;
}, handleError)

serviceMain.interceptors.response.use((response) => response, handleError)

const installer = {
    vm: {},
    install(Vue) {
        Vue.use(VueAxios, serviceMain)
    },
}

export { installer as VueAxios, serviceMain as axiosMain }