<template>
  <div class="wrapper-presale"><div class="presale-corner"></div></div>
  <TheContainer>
    <template v-slot:content>
      <div class="title">
        <h1>{{ title }}</h1>
      </div>
      <template v-for="(item, idx) in items" :key="idx">
        <PresaleKitTtitle
          :title="item.title"
          :subtitle="item.description"
          :link-name="item.file?.data.attributes.name"
          :link-link="item.file?.data.attributes.url"
        />
      </template>
    </template>
  </TheContainer>
</template>

<script>
import ApiUtil from "@/utils/api";
import TheContainer from "@/components/layout/TheContainer.vue";
import PresaleKitTtitle from "@/components/base/PresaleKitTtitle.vue";
export default {
  name: "PresaleKitView",
  components: {
    TheContainer,
    PresaleKitTtitle,
  },
  data() {
    return {
      title: "",
      items: [],
    };
  },
  created() {
    this.get();
  },

  methods: {
    async get() {
      let populate = ["content.file"];
      let res = await ApiUtil({
        url: "poleznaya-informacziya?populate=" + populate.join(","),
        method: "get",
      });
      let pageData = res.data.data.attributes;
      this.title = pageData.title;
      this.items = pageData.content;
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/css/mixins.scss";
h1 {
  color: #000;
  font-size: 108px;
  font-weight: 400;
  line-height: 112px; /* 103.704% */
  text-transform: uppercase;
  margin: 0px;
  @include md {
    font-size: 55px;
    line-height: 60px; /* 103.704% */
  }
  @include sm {
    font-size: 35px;
    line-height: 40px; /* 98.148% */
  }
}
.title {
  padding-top: 42px;
  padding-bottom: 88px;
  @include md {
    padding-top: 22px;
    padding-bottom: 48px;
  }
  @include sm {
    padding-top: 40px;
    padding-bottom: 30px;
  }
}

.wrapper-presale {
  width: 40rem;
  height: 40rem;
  right: 0px;
  top: -150px;
  position: absolute;
  overflow: hidden;
  @include md {
    width: 20rem;
    height: 20rem;
    top: -100px;
  }
  @include sm {
    width: 10rem;
    height: 10rem;
    top: -10px;
    right: 0px;
  }
}
.presale-corner {
  width: 100%;
  height: 100%;
  transform: scaleX(-1) rotate(-29.761deg);
  background-image: url("~@/assets/images/presale-corner.png");
  position: absolute;
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 0.5;
  z-index: -1;
}
</style>
