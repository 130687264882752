<template>
  <div class="image-wrapper">
    <div class="image"></div>
  </div>
  <TheContainer>
    <template v-slot:content>
      <h1 class="top">{{ topText }}</h1>
      <h1 class="bottom">{{ bottomText }}</h1>
      <div class="link">
        <div>
          <a class="present-link" :href="presentLink" target="_blank">
            <!--  <img :src="downloadIcon" class="download" />-->
            {{ presentName }}
          </a>
          /

          <a class="present-link" :href="techLink" target="_blank">
            {{ techName }}
            <!--  <img :src="downloadIcon" class="download" />-->
          </a>
        </div>
      </div>
    </template>
  </TheContainer>
  <!--<div class="main-container">
    <div class="scrolling-text-container">
      <div class="scrolling-text" ref="text">
        {{ scrollingText }}
      </div>
    </div>
  </div>-->
</template>

<script>
import TheContainer from "@/components/layout/TheContainer.vue";
export default {
  name: "TopPage",
  props: {
    msg: String,
    pageData: Object,
  },
  data() {
    return {
      downloadIcon: require("@/assets/svg/download.svg"),
      topText: "",
      bottomText: "",
      presentLink: "",
      techLink: "",
      presentName: "",
      techName: "",
      scrollingText: "",
    };
  },
  components: {
    TheContainer,
  },
  created() {},
  mounted() {
    this.get();
    //  window.addEventListener("scroll", this.handleScroll);
  },
  unmounted() {
    //  window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      const scrolled = window.scrollY * 5;
      this.$refs.text.style.transform = `translateX(-${scrolled}px)`;
    },
    async get() {
      let pageData = this.pageData;
      this.topText = pageData.mainBanner.topText;
      this.bottomText = pageData.mainBanner.bottomText;
      this.scrollingText = pageData.scrollingText.content;
      if (pageData.mainBanner.presentation.data != null) {
        this.presentLink = pageData.mainBanner.presentation.data.attributes.url;
        this.presentName = pageData.mainBanner.presentation.data.attributes.name;
      }

      if (pageData.mainBanner.techPresentation.data != null) {
        this.techLink = pageData.mainBanner.techPresentation.data.attributes.url;
        this.techName = pageData.mainBanner.techPresentation.data.attributes.name;
      }
      console.log(this.techLink);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/assets/css/mixins.scss";

h1 {
  color: #000;
  font-size: 6rem;
  font-weight: 400;
  line-height: 6.5rem; /* 98.148% */
  text-transform: uppercase;
  @include md {
    color: #000;
    font-size: 52px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
  }
  @include sm {
    font-size: 35px;
    line-height: 40px; /* 98.148% */
  }
}

.top {
  width: 100%;
  text-align: left;
  width: 922px;
  @include md {
    width: 50%;
  }
  @include sm {
    width: 150px;
    padding-top: 50px;
  }
}
.bottom {
  padding-top: 7rem;
  width: 100%;
  text-align: right;
  width: 922px;
  margin-left: auto;
  margin-bottom: 0px;
  @include md {
    width: 50%;
  }
  @include sm {
    width: 220px;
    margin-left: auto;
  }
}

.link {
  cursor: pointer;
  margin-left: auto;
  color: #000;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: right;
  display: flex;
  justify-content: end;

  @include md {
    font-size: 16px;
    justify-content: space-around;
  }
  @include sm {
    font-size: 16px;
    justify-content: space-around;
  }
}

.main-container {
  position: relative;
  display: flex;
  justify-content: space-around;
}
.scrolling-text-container {
  overflow: hidden;
  position: relative;
  border-radius: 12px;
  top: 0;
  height: 115px; /* Adjust the height as needed */

  width: 1330px;
  margin: 0px 0px;
  margin-top: 88px;
  @include md {
    width: 720px;
    height: 98px; /* Adjust the height as needed */
    margin: 0px 50px;
    margin-top: 68px;
  }
  @include sm {
    width: 100%;
    margin: 0px 20px;
    margin-top: 10px;
    height: 50px; /* Adjust the height as needed */
  }
}
.present-link {
  text-decoration: none;
  color: black;
  border-radius: 12px;
  padding: 5px 10px;
  &:hover {
    transition: 0.3s;
    background: #21a56e;
    color: white;

    @include sm {
      background: none;
      color: black;
    }
  }
}
.scrolling-text {
  white-space: nowrap;
  font-size: 108px;
  font-weight: 400;
  line-height: 112px; /* 103.704% */
  color: white; /* Set the font color to white */
  text-shadow: -1px -1px 0 black, 1px -1px 0 black, -1px 1px 0 black, 1px 1px 0 black; /* Add a black stroke */
  text-transform: uppercase;
  box-sizing: content-box;
  margin-left: 60vw;
  @include md {
    font-size: 58px;
    line-height: 112px;
  }
  @include sm {
    font-size: 38px;
    line-height: 50px;
  }
}
.download {
  padding: 0px 12px;
  &:hover {
  }
  @include sm {
    padding: 0px 0px;
    height: 15px;
  }
}
.line {
  margin-top: 88px;
  width: 1000px;
  height: 0px;
  stroke-width: 2px;
  stroke: #000;
  opacity: 0.1;
  @include md {
    margin-top: 24px;
    width: 700px;
  }
  @include sm {
    margin-top: 10px;
    width: 300px;
  }
}

.image-wrapper {
  position: absolute;
  width: 100%;
  height: 40rem;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: -1;
  @include sm {
    height: 30rem;
  }
}

.image {
  width: 50rem;
  height: 50rem;
  background-image: url("~@/assets/images/main.png");
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -1;
  @include md {
    width: 30rem;
    height: 30rem;
  }
  @include sm {
    width: 20rem;
    height: 20rem;
  }
}
</style>
