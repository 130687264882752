<template>
  <template v-for="itemfour in chunks(steps, 4)" :key="itemfour">
    <a
      style="all: unset"
      :href="link"
      target="blank"
      :style="{ cursor: link ? 'pointer' : 'default' }"
    >
      <div class="container-fluid py-5">
        <div class="row">
          <div class="col-lg-12">
            <div class="horizontal-timeline">
              <ul class="list-inline items">
                <template v-for="item in itemfour" :key="item.id">
                  <li class="list-inline-item items-list">
                    <div class="px-4">
                      <div
                        class="badge"
                        :style="{ background: item.topColor || '#21a56e' }"
                      >
                        {{ item.date }}
                      </div>
                      <h5 class="pt-2">{{ item.topText }}</h5>
                      <p class="text-muted">
                        {{ item.bottomTitle }}
                      </p>
                    </div>
                  </li>
                </template>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </a>
  </template>
</template>

<script>
export default {
  name: "StepperCmp",
  props: { steps: Array },
  computed: {},
  methods: {
    chunks(a, size) {
      return Array.from(new Array(Math.ceil(a.length / size)), (_, i) =>
        a.slice(i * size, i * size + size)
      );
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/css/mixins.scss";

.horizontal-timeline .items {
  border-top: 3px solid #e9ecef;
}

.horizontal-timeline .items .items-list {
  display: block;
  position: relative;
  text-align: center;
  padding-top: 70px;
  margin-right: 0;
}

.horizontal-timeline .items .items-list:before {
  content: "";
  position: absolute;
  height: 36px;
  border-right: 2px dashed #dee2e6;
  top: 0;
}

.horizontal-timeline .items .items-list .event-date {
  position: absolute;
  top: 36px;
  left: 0;
  right: 0;
  width: 75px;
  margin: 0 auto;
  font-size: 0.9rem;
  padding-top: 8px;
}

@media (min-width: 1140px) {
  .horizontal-timeline .items .items-list {
    display: inline-block;
    width: 24%;
    padding-top: 45px;
    vertical-align: top;
  }

  .horizontal-timeline .items .items-list .event-date {
    top: 40px;
  }
}
</style>
